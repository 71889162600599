import request from "../api/request";
import utils from "../../utils/index";
import pfile from "@/utils/secretkey/pfile";
import sfile from "@/utils/secretkey/sfile";

var ak = utils.genak();
var ed = utils.re(ak, unescape(pfile)); //秘钥

function decrypt(data) {
  if (data?.ak) {
    let plaintext = utils.rd(data.ak, unescape(sfile));
    let obj = utils.secret(data.ed, plaintext, true);
    return JSON.parse(obj);
  } else {
    return data;
  }
}

function inParameter(data) {
  if (data) {
    return { sn: ed, jt: utils.secret(data, ak) };
  } else {
    return data;
  }
}
//获取会员信息
export function payMemberListSimpleAPI() {
  return request({
    url: "/member-service/member/payMemberListSimple",
    method: "post",
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}
//获取会员信息
export function pcPayMemberInfoAPI(data) {
  return request({
    url: "/user-service/member/pcPayMemberInfo",
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}
//查询订单状态
export function getOrderStateAPI(data) {
  return request({
    url: `/member-service/order/status`,
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}
//使用优惠卷
export function couponValidateAPi(data) {
  return request({
    url: "/member-service/order/couponValidate",
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}
//生成订单信息
export function generatePaySignAPI(data) {
  return request({
    url: "/member-service/order/generatePaySign",
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}
//订单购买轮播明细
export function getBuyListAPI(data) {
  return request({
    url: "/member-service/static/getBuyList",
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}
//查询连续订单状态
export function getSpecStatusAPI(data) {
  return request({
    url: `/member-service/order/specStatus`,
    method: "post",
    data: inParameter(data),
  })
    .then((res) => {
      res.data = decrypt(res.data);
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}
