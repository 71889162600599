<template>
  <div id="fmCloud" class="fmCloud" @mousemove="selectItems">
    <div class="fixed-top" :style="`width:${$store.state.appRightWidth}px`">
      <TabList
        ref="tabList"
        curId="0"
        :placeholder="$t(filePlaceholderTop)"
        @setNav="setNav"
        @searchList="searchList"
      ></TabList>
      <div class="fc-second" @mouseenter="handleMouseenter($event, -1, -100)">
        <div class="add-box">
          <div
            class="btn-add"
            @mouseleave="isFmAddHove = false"
            @mouseenter="isFmAddHove = true"
          >
            <fm-button
              icon="icon-cloud-add"
              round
              size="medium"
              @click="toUpload()"
              >{{$t('cloud.upload')}}</fm-button
            >
            <div class="addMenu" :class="isFmAddHove ? 'addMenuHover' : ''">
              <span @click="toUpload()">{{$t('cloud.upname1')}}</span>
              <span @click="toUpload('folder')">{{$t('cloud.upname2')}}</span>
              <label
                ref="selectFolderLabel"
                style="display: none"
                for="selectFolder"
              ></label>
            </div>
          </div>
          <fm-button
            @click="newFloder"
            icon="icon-cloud-folder"
            type="info"
            plain
            round
            size="medium"
            >{{$t('cloud.newFloder')}}</fm-button
          >
        </div>
        <div class="volume-box" v-if="checkedFiles.length == 0">
          <div class="top">
            <el-tooltip v-if="diskSize.is_rate_flow == 1" placement="bottom-start">
              <template #content>
                <div class="tooltip-Spacelevel">
                  <div class="tooltip-item" v-for="(item,index) in diskSize.cloud_size_text" :key="index">{{item}}</div>
                </div>
              </template>
              <span class="title">{{$t('cloud.CloudSpace')}}<svg-btn class="Spacelevel" icon-class="icon-Spacelevel"></svg-btn></span>
            </el-tooltip>
            <span v-else class="title">{{$t('cloud.CloudSpace')}}</span>
            <span class="size" v-if="diskSize.cloud_size">
              {{ diskSize.self_size }}G /
              <font :class="{ endless: diskSize.cloud_size == '999999' }">
                {{
                  diskSize.cloud_size == "999999"
                    ? "∞"
                    : diskSize.cloud_size + "G"
                }}</font
              >
            </span>
            <span class="size" v-else>/</span>
          </div>

          <el-progress
            :percentage="usedPre > 100 ? 100 : usedPre"
            :stroke-width="4"
            :text-inside="true"
          ></el-progress>
        </div>
        <div class="multiple-operation" v-else>
          <div v-if="!isHasLockFile() && checkidsAllInfo.filter(item =>item.is_file == 0).length == 0" @click="goShare(checkidsAllInfo[0])">
            <svg-btn icon-class="icon-cloud-share"></svg-btn>
            <span>{{$t('cloud.rightShare')}}</span>
          </div>
          <div @click="checksMoveDel(2)">
            <svg-btn icon-class="icon-multiple-delete" />
            <span>{{$t('cloud.rightDelete')}}</span>
          </div>
          <div
            @click="selectRename()"
            v-show="checkedFiles.length < 2 && !isHasLockFile()"
          >
            <svg-btn icon-class="icon-cloud-rename" />
            <span>{{$t('cloud.rightRename')}}</span>
          </div>
          <div v-show="!isHasLockFile()" @click="checksMove(2)">
            <svg-btn icon-class="icon-cloud-move" />
            <span>{{$t('cloud.rightMoveto')}}...</span>
          </div>
        </div>
      </div>
      <div class="file-type">
        <div class="file-directory" v-if="routerList.length > 1">
          <div class="lessthen4" v-if="routerList.length < 4">
            <div
              class="one"
              v-for="(item, index) in routerList"
              :key="index"
              @click="toUp(item, index)"
              @mouseenter="handleMouseenter($event, -1, item.id)"
            >
              <el-tooltip :content="item.name == '全部文件' ? $t('cloud.allFiles') : item.name" placement="top">
                <span
                  class="el-dropdown-link name"
                  :style="moveNav.length == 1 ? 'color:#8A8B92 !important' : ''"
                >
                  {{ item.name == '全部文件' ? $t('cloud.allFiles') : item.name }}</span
                >
              </el-tooltip>
              <svg-btn icon-class="icon-file-direction" />
            </div>
          </div>
          <div class="lessthen4 morethen4" v-else>
            <div
              class="one"
              @mouseenter="handleMouseenter($event, -1, 0)"
              @click="toUp({ name: '全部文件', id: 0 }, 0)"
            >
              <el-tooltip :content="$t('cloud.allFiles')" placement="top">
                <span class="el-dropdown-link name"> {{ $t('cloud.allFiles') }} </span>
              </el-tooltip>
              <svg-btn icon-class="icon-file-direction" />
            </div>
            <div
              class="one"
              @mousemove="showboxBreadMove = true"
              @mouseleave="showboxBreadMove = false"
            >
              ...
              <svg-btn icon-class="icon-file-direction" />
            </div>
            <div
              class="one"
              v-for="item in routerList.slice(
                routerList.length - 2,
                routerList.length
              )"
              :key="item"
              @mouseenter="handleMouseenter($event, -1, item.id)"
              @click="toUp(item, folderList.length - 2)"
            >
              <el-tooltip :content="item.name == '全部文件' ? $t('cloud.allFiles') : item.name" placement="top">
                <span class="el-dropdown-link name">
                  {{ item.name == '全部文件' ? $t('cloud.allFiles') : item.name }}
                </span>
              </el-tooltip>
              <svg-btn icon-class="icon-file-direction" />
            </div>
          </div>
          <div
            class="boxBreadMove"
            v-show="
              showboxBreadMove &&
              routerList.slice(1, routerList.length - 2).length > 0
            "
            @mouseleave="showboxBreadMove = false"
            @mousemove="showboxBreadMove = true"
          >
           <div class="boxbreadbox2">
             <div
              class="boxOne pointer"
              v-for="(item, index) in routerList.slice(
                1,
                routerList.length - 2
              )"
              :key="index"
              @click="toUp(item, index + 1)"
              @mouseenter="handleMouseenter($event, -1, item.id)"
            >
              {{ item.name }}
            </div>
           </div>
           
          </div>
        </div>
        <div class="search-result" v-else-if="params.keywords !== ''">
          <el-tooltip
            class="item r-tooltip"
            effect="dark"
            :content="$t('cloud.allFiles')"
            placement="top"
          >
            <span class="all" @click="resetRouter">{{$t('cloud.allFiles')}}</span>
          </el-tooltip>
          <div class="r-search">
            <svg-btn icon-class="icon-file-direction" />
            <div class="r-search-tit">
              {{ $utils.searchTitSlice(params.keywords) }}
            </div>
          </div>
        </div>
        <div
          class="search-result"
          @mouseenter="handleMouseenter($event, -1, 0)"
          v-else
        >
          <el-tooltip
            class="item r-tooltip"
            effect="dark"
            :content="$t('cloud.allFiles')"
            placement="top"
          >
            <span class="all" @click="resetRouter">{{ $t('cloud.allFiles') }}</span>
          </el-tooltip>
          <div class="total">
            {{ allFileTotal }}
          </div>

            <LoopText></LoopText>
        </div>
        <div class="fileClass">
          <span
            @click="chageClass(item.id)"
            v-for="item in fileType"
            :key="item.id"
            :class="['label', { curStyle: item.id == fileTypeId }]"
            >{{ $t(item.name) }}</span
          >
          <div class="screenBtn">
            <fm-button
              icon="icon-cloud-screen"
              type="info"
              text
              size="small"
            ></fm-button>
            <div class="screenBox">
              <div
                @click="changeSort(item.id)"
                v-for="item in screenList"
                :key="item.id"
                :class="{
                  curScreen: item.id == screenId 
                }"
              >
                <svg-btn icon-class="icon-cloud-check" />
                <span>{{ item.name }}</span>
              </div>
            </div>
          </div>
          <span class="line"></span>
          <fm-button
            class="icon-refresh"
            @click="refresh"
            icon="icon-refresh"
            type="info"
            text
            size="small"
          ></fm-button>
        </div>
      </div>
    </div>
    <!-- 列表 -->
    <div
      v-loading="loading"
      :element-loading-text="upZipIng? '解压中':''"
      class="file-content-box"
      @mousedown="handleMouseDown($event, -1)"
      @dragenter.prevent
      @dragover.prevent
      @drop.prevent="onFileDrop"
    >
      <div v-show="total != 0" class="tab-th" @dragenter.prevent="setDragIndex">
        <div class="selectAll">
          <div @click="allCheck == 2 ? cancelCheckedVal() : setCheckedVal()">
            <svg-btn
              :icon-class="
                allCheck == 2
                  ? 'icon-file-check'
                  : allCheck == 0
                  ? 'icon-file-uncheck-' + $store.state.theme
                  : 'icon-file-checks'
              "
            >
            </svg-btn>
          </div>
          <span>{{$t('cloud.fileName')}}</span>
        </div>
        <div class="lineup2" style="cursor: auto;">
          <span>{{$t('cloud.browseDown')}}</span>
        </div>
        <div class="lineup2" @click="changeSort(1,true)">
          <span>{{$t('cloud.size')}}</span>
          <img
            v-show="screenId == 1 "
            :src="
              params.size == 1
                ? $utils.getPng('web4/icon_order_descending')
                : $utils.getPng('web4/icon_order_ascending')
            "
          />
        </div>
        <div class="lineup3" @click="changeSort(0,true)">
          <span>{{$t('cloud.creatTime')}}</span>
          <img
            v-show="screenId == 0 "
            :src="
              params.time == 1
                ? $utils.getPng('web4/icon_order_descending')
                : $utils.getPng('web4/icon_order_ascending')
            "
          />
        </div>
      </div>
      <div class="file-list" v-show="filesList.length > 0">
        <QVirtualList
          ref="virtualBox"
          :items="filesList"
          :item-size="virtualDom.itemSize"
          :is-over="isOver"
          id="qVirtualDom"
          class="file-virtual-list"
          key-field="id"
          @onEnd="getFiles"
          @onScroll="scrollBoxFun"
          @dragleave.native.prevent="listDragleave"
          @getVlBoxAttr="onGetVlBoxAttr"
        >
          <template v-slot:default="{ item, index }">
            <div
              :key="item.id"
              :id="'upload-' + item.id"
              :class="{
                hoverItem: item.isHover || checkedFiles.indexOf(index) > -1,
                'active-1': dragoverIndex === index,
                inFirst: dragoverIndex === index && virtualDom.inFirst === 1,
                'file-item-hover-1':
                  $store.state.showFlag == 2 && item.is_file == 0,
              }"
              class="file-item-hover"
              @mousedown="handleMouseDown($event, index)"
              @mouseenter="handleMouseenter($event, index)"
              @dragover.prevent="(ev) => onFileDragover(ev, item, index)"
              @dragleave.prevent="(ev) => onFileDragleave(ev, item)"
              @dblclick="item.is_file == '0'? openFolder(item.id, item.name): item.is_locked == 0? previewFile(item): ''"
            >
              <a-dropdown :trigger="dropdown" @visibleChange="visibleChange">
                <div
                  class="file-item cursorbtn"
                  :class="{ lockeditem: item.is_locked == '1' }"
                >
                <!-- 加了一个div是为了阻止chooseFile单击事件触发了双击事件 -->
                  <div class="icon_checks" @dblclick.stop>
                    <svg-btn
                      class="icon_checks"
                      @click.prevent="chooseFile(index)"
                      :icon-class="
                        checkedFiles.indexOf(index) > -1
                          ? 'icon-file-check'
                          : 'icon-file-uncheck-' + $store.state.theme
                      "
                    ></svg-btn>
                  </div>
                  <div class="lineup1" v-show="item.isShowEdit">
                    <div class="openFoldername" @dblclick.stop>
                      <div class="file_icon">
                        <img class="icon" @error="onError($event, item)"
                        :src="item.is_file == '0' ? item.ext_icon : item.showPlay ? item.ext_icon : $utils.fileIcon(item.extension)"/>
                        <div
                          class="pay"
                          v-show="item.ext == 'mp4' && item.showPlay"
                        >
                          <img
                            class="iconpay"
                            :src="$utils.getPng(`web4/icon_pay`)"
                          />
                        </div>
                      </div>
                      <!-- <el-input
                        v-model="rename"
                        :class="{ paddingRight: item.is_file == '1' }"
                        class="edit-input"
                        placeholder=""
                        @blur.stop="editName(item)"
                        @keyup.enter.native.stop="$event.target.blur()"
                      > -->
                      <el-input
                        v-model="rename"
                        :class="{ paddingRight: item.is_file == '1' }"
                        class="edit-input"
                        placeholder=""
                        @blur.stop="editName(item)"
                        @keyup.enter.native.stop="editName(item)"
                      >
                        <span
                          class="ext"
                          v-show="item.is_file == '1'"
                          slot="suffix"
                        >
                          {{ item.extension }}
                        </span>
                      </el-input>
                    </div>
                  </div>
                  <div class="lineup1" v-show="!item.isShowEdit">
                    <div
                      class="openFoldername"
                    >
                    <div class="file_icon">
                        <img class="icon" @error="onError($event, item)"
                        :src="item.is_file == '0' ? item.ext_icon : item.showPlay ? item.ext_icon : $utils.fileIcon(item.extension)"/>
                        <div
                          class="pay"
                          v-show="item.ext == 'mp4' && item.showPlay"
                        >
                          <img
                            class="iconpay"
                            :src="$utils.getPng(`web4/icon_pay`)"
                          />
                        </div>
                      </div>
                      <el-tooltip
                        :disabled="tooltipDisabled"
                        open-delay="1000"
                        popper-class="item list-tooltip"
                        effect="dark"
                        :content="`${item.name}${
                          item.extension ? item.extension : ''
                        }`"
                        placement="bottom"
                      >
                        <span
                          class="name"
                          @mouseleave="tooltipDisabled = true"
                          @mouseenter="tooltipDisabled = false"
                          >{{ item.name }}{{ item.extension }}</span
                        >
                      </el-tooltip>
                      <div class="locked-info" v-show="item.is_locked == '1'">
                        <svg-btn icon-class="icon-tips-warning" />
                        <span>已锁定 </span>
                      </div>
                    </div>
                  </div>
                  <div class="tools-box">
                    <div
                      class="itemFilesTools"
                      @dblclick.stop
                      v-show="checkedFiles.length < 2"
                    >
                      <template v-if="item.is_locked == '0'">
                        <div
                          class="act"
                          @click="item.is_file == '0'? openFolder(item.id, item.name): item.is_locked == 0? previewFile(item): ''"
                          v-show="item.ext != 'zip' && item.is_file_open == '1' || item.is_file == '0'"
                        >
                          <el-tooltip
                            open-delay="1000"
                            popper-class="item list-tooltip"
                            effect="dark"
                            :content="item.is_file == '0'?'进入':item.is_locked == 0?'打开':''"
                            placement="bottom"
                          >
                            <svg-btn :icon-class="item.is_file == '0'?'icon-gofolder':'icon-look'" iconColor="iconColor2"></svg-btn>
                          </el-tooltip>
                        </div>
                        <div
                          class="act"
                          @click="previewFile(item)"
                          v-show="item.ext == 'zip'"
                        >
                          <el-tooltip
                            open-delay="1000"
                            popper-class="item list-tooltip"
                            effect="dark"
                            content="解压"
                            placement="bottom"
                          >
                            <svg-btn icon-class="icon-cloud-zip" iconColor="iconColor2"></svg-btn>
                          </el-tooltip>
                        </div>
                        <div
                          class="act"
                          v-show="item.is_file == '1' && item.only_pc_down == 0"
                          @click.stop="goDownload($event,item)"
                        >
                          <el-tooltip
                            open-delay="1000"
                            popper-class="item list-tooltip"
                            effect="dark"
                            content="下载"
                            placement="bottom"
                          >
                            <svg-btn icon-class="icon-tool-download" iconColor="iconColor2"></svg-btn>
                          </el-tooltip>
                        </div>
                        <div
                          class="act"
                          v-show="item.is_file == '1'"
                          @click.stop="electronFn2(item)"
                        >
                          <el-tooltip
                            open-delay="1000"
                            popper-class="item list-tooltip"
                            effect="dark"
                            content="客户端下载"
                            placement="bottom"
                          >
                            <svg-btn icon-class="icon-tool-pcdown" iconColor="iconColor2"></svg-btn>
                          </el-tooltip>
                        </div>
                        <div
                          class="act"
                          v-show="item.is_file == '1'"
                          @click.stop="goShare(item)"
                        >
                          <el-tooltip
                            open-delay="1000"
                            popper-class="item list-tooltip"
                            effect="dark"
                            content="分享"
                            placement="bottom"
                          >
                            <svg-btn icon-class="icon-cloud-share" iconColor="iconColor2"></svg-btn>
                          </el-tooltip>
                        </div>
                        <div class="act" @click.stop="handleCommand('a', item)">
                          <el-tooltip
                            open-delay="1000"
                            popper-class="item list-tooltip"
                            effect="dark"
                            content="移动"
                            placement="bottom"
                          >
                            <svg-btn icon-class="icon-cloud-move" iconColor="iconColor2"></svg-btn>
                          </el-tooltip>
                        </div>
                        <div class="act">
                          <el-dropdown
                            @visible-change="changeVisible($event, item)"
                            @command="(command) => handleCommand(command, item)"
                            placement="bottom-start"
                          >
                            <div>
                              <svg-btn icon-class="icon-cloud-more" iconColor="iconColor2"></svg-btn>
                            </div>
                            <el-dropdown-menu
                              class="moreAct"
                              slot="dropdown"
                              :class="isOpen ? '' : 'isOpen1'"
                            >
                              <el-dropdown-item command="b"
                                >{{ $t('cloud.rightRename') }}</el-dropdown-item
                              >
                              <el-dropdown-item command="c"
                                ><span>{{ $t('cloud.rightDelete') }}</span></el-dropdown-item
                              >
                            </el-dropdown-menu>
                          </el-dropdown>
                        </div>
                      </template>
                      <template v-else>
                        <div class="act" @click="handleCommand('c', item)">
                          <el-tooltip
                            open-delay="1000"
                            popper-class="item list-tooltip"
                            effect="dark"
                            content="删除"
                            placement="bottom"
                          >
                            <svg-btn
                              icon-class="icon-multiple-delete"
                            ></svg-btn>
                          </el-tooltip>
                        </div>
                      </template>
                    </div>
                  </div>
                  <div class="lineup2">
                    {{ item.show_view_down_model}}
                  </div>
                  <div class="lineup2">
                    {{ item.is_file == "1" ? item.size : item.fileCount }}
                  </div>
                  <div class="lineup3">
                    {{ item.intime }}
                  </div>
                </div>
                <template #overlay>
                  <a-menu
                    class="menu-a"
                    :class="isOpen2 ? 'menu-b' : ''"
                    v-if="isOpen"
                  >
                    <a-menu-item
                      @click="rightHandle($event,menu_item.id, item)"
                      v-for="menu_item in isHasLockFile()
                        ? list3
                        : isSelectMore
                        ? checkidsAllInfo.filter(item =>item.is_file == 0).length == 0? list5: list4
                        : item.is_locked == '1'
                        ? list3
                        : item.is_file == '1'
                        ? list1
                        : list2"
                      :key="menu_item.id"
                      :class="menu_item.id == -1 ? 'line' : 'me-item'"
                      >{{ $t(menu_item.name)}}
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
              <div class="one-dra">
                <img :src="$utils.getPng('web4/icon_cloud_upload')" />
                <span>
                  上传到{{ item.is_file == "0" ? "当前" : "同级" }}目录
                </span>
              </div>
            </div>
          </template>

          <template v-slot:noMore>
            <div
              class="isOver"
              v-if="loadNextPage"
              v-loading="loadNextPage"
            ></div>
            <div class="isOver" v-if="isOver && showEnd">{{$t('cloud.noMore')}}</div>
          </template>
        </QVirtualList>
        <div
          v-show="showFlag == 1"
          class="selection-box"
          :style="{
            left: selectionBox.left + 'px',
            top: selectionBox.top + 'px',
            width: selectionBox.width + 'px',
            height: selectionBox.height + 'px',
          }"
        ></div>
      </div>
      <div
        class="file-default"
        v-show="!loading && filesList.length == 0 && params.keywords != ''"
      >
        <img :src="$utils.getPng('web4/file_default')" />
        <div class="search">
          <span>{{ $t('cloud.notFile') }}</span> <span>{{ $t('cloud.trySearchOther') }}</span>
        </div>
      </div>
      <div
        @dragover.prevent="(ev) => onFileDragover(ev, '-1')"
        @dragleave.prevent="(ev) => onFileDragleave(ev, '-1')"
        v-show="!loading && filesList.length == 0 && params.keywords == ''"
        class="upload-default"
        id="upload-0"
      >
        <img class="default-img" :src="$utils.getPng('web4/file_default')" />
        <div class="tit">
          <span>{{ $t('cloud.dragFiles0') }}&nbsp;</span>
          <span class="tit2">&nbsp;{{ $t('cloud.dragFiles1') }}</span>
        </div>
        <fm-button icon="icon-cloud-add" round size="medium" @click="toUpload()"
          >{{ $t('cloud.upload') }}</fm-button
        >
        <span class="desc">（{{ $t('cloud.cannot4g') }}）</span>
        <div class="top2">
          <svg-btn icon-class="icon-tips-circle-fill" class="icon"></svg-btn>
          <div class="top2Tit">
            {{ $t('cloud.xieyinot') }}
            <span class="text pointer" @click="goAskdetail(1)"
              >{{ $t('agreement.dispositionOfOffendingAccounts') }}</span
            >
            <span class="text pointer" @click="goAskdetail(2)"
              >{{ $t('agreement.disclosureOfContentReviewMechanism') }}</span
            >
            <span class="text pointer" @click="goAskdetail(3)"
              >{{ $t('agreement.disclaimerAgreement') }}</span
            >
          </div>
        </div>
      </div>
    </div>

    <!-- 云盘空间已满 升级会员-->
    <PromoteDialog
      @handleClose="isShowPromote = false"
      @handleClose2="handleClose2Fn"
      :type="promoteType"
      :msg="promoteMsg"
      :isShow="isShowPromote"
      :vip_update="vip_update"
      :icon="icon"
    ></PromoteDialog>
    <!-- 分享文件 -->
    <ShareDialog
      ref="shareDialog"
      :boxType="boxType"
      :isShow="isShowShareDialog"
      :handleItem="handleItem"
      :shareUrl="shareUrl"
      :checkedFiles="checkids"
      :auto_set="auto_set"
      :isSysCode="isSysCode"
      :pucode="pucode"
      :open_pucode="open_pucode"
      :incomeList="incomeList"
      @handleClose="isShowShareDialog = false"
      @setShare="setShare"
    ></ShareDialog>
    <!-- 分享多个文件出现的链接板 -->
    <ShareMoreLastDialog ref="ShareMoreLastDialog"></ShareMoreLastDialog>
    <!-- 分享提示 -->
    <BaseDialog
      :isShow="isShowBaseDialog"
      :text="shareTips"
      :showCancel="false"
      @confirm="openShareDialog"
      @handleClose="isShowBaseDialog = false"
      title="提示"
      >确 认</BaseDialog
    >
    <!-- 新建文件夹 1 新建文件夹 2修改文件夹名 3修改文件名 -->
    <RenameFile
      :reextension="reextension"
      :type="inputType"
      @handleName="handleName"
      @handleClose="isShowInputDialog = false"
      :isShow="isShowInputDialog"
      ref="RenameFile"
    ></RenameFile>
    <!-- 绑定手机1  实名认证2 验证码3-->
    <BindPhone
      :isShow="isShowBindPhone"
      :type="bindType"
      :time="time"
      @getPhoneCode="getPhoneCode"
      @bindPhone="bindPhone"
      @confirmNameAuth="realNameAuth"
      @handleClose="closeBindPhone"
    ></BindPhone>
    <!-- 回收站提示 -->
    <RecycleTips
      @handleClose="isShowTipDialog = false"
      @doDel="doDel"
      :isShow="isShowTipDialog"
      title="提示"
      :recycle_expire_day="recycle_expire_day"
      :recycle_tit="recycle_tit"
    ></RecycleTips>
    <!-- 设置文件目录 -->
    <FileDirDialog
      @changeNav="changeNav"
      @getFolder="getFolderMoveList"
      @doFileDir="doFileMove"
      @handleClose="isShowFileDir = false"
      :isShow="isShowFileDir"
      :list="folderList"
      :moveNav="moveNav"
      type="3"
    ></FileDirDialog>
    <!-- 预览 -->
    <PreviewPublic
      ref="previewPublic"
      id="preview_Box"
      @get-instance="getInstance"
      :dialogVisible="showPreviewPublic"
      :handleItem="handleItem"
      :docData="docData"
      :fileType="previewFileType"
      :txtData="txtData"
      :previewUrl="previewFileType == 9 ? previewUrl : ''"
      :previewUrl1="previewFileType == 3 ? previewUrl : ''"
      :previewImgData="previewImgData"
      :loadDoc="loadDoc"
      :expire="expire"
      @handleClose="closePreview"
      @openInputDialog="openInputDialog"
      @openDelTipDialog="checksMoveDel"
      @handleCommand="handleCommand"
      @previewImg="previewImg"
      @goDownload="goDownload"
      @goShare="goShare"
    ></PreviewPublic>
    <Riskverification
      ref="Riskver"
      @riskveAgain="riskveAgain"
    ></Riskverification>

    <UpZipDialog ref="UpZipDialog" :isShow="showUpZipDialog" :handleItem="handleItem" @handleClose="closeZip" :zipPwd="zipPwd"></UpZipDialog>

    <!-- 密码 -->
    <PressPwd
      ref="pressPwd"
      :isShow="showPressPwd"
      @handleClose="closePwdBox"
      @confirm="confirmPwd"
    ></PressPwd>
      
    <!-- 文件过大客户端唤起下载 -->
    <Electronpop2
      :code="iscode"
      :isElectron="isElectron2"
      :isdirect="true"
      :isType="2"
      @Visible="isElectron2 = false"
    ></Electronpop2>

    <div class="toTop-div" v-show="showTop" @click="scrollToTop">
      <svg-btn icon-class="icon-toTop" style="width: 24px;height: 24px;"></svg-btn>
    </div>
  </div>
</template>

<script>
import TabList from "./TabList/index.vue";
import PromoteDialog from "@/components/Dialog/PromoteDialog";
import BaseDialog from "@/components/Dialog/BaseDialog";
import RenameFile from "@/components/Cloud/RenameFile";
import RecycleTips from "@/components/Cloud/RecycleTips";
import FileDirDialog from "@/components/Dialog/FileDirDialog";
import PreviewPublic from "@/components/PreviewPublic/index";
import BindPhone from "@/components/Cloud/BindPhone";
import ShareDialog from "@/components/Cloud/ShareDialog";
import ShareMoreLastDialog from "@/components/Cloud/ShareMoreLastDialog";
import QVirtualList from "@/components/QVirtualList/qVirtualList.vue";
import Riskverification from "@/components/Dialog/Riskverification.vue";
import UpZipDialog from "@/components/Cloud/UpZipDialog.vue";
import PressPwd from "@/components/Cloud/PressPwd";
import Electronpop2 from "@/components/DownPage/Electronpop2";

import {
  getCloudList,
  modiFolder,
  folderMoveList,
  fileMove,
  recycleFile,
  fileReName,
  setShare,
  setBatchSetShare,
  previewTask,
  officeDocument,
  diskCapacity,
  previewAudiovisual,
  previewImg,
  downloadFile,
  restoreFile,
  getExpireDay,
  findFolderInfo,
  hebing,
  queryZipInfoTask,
  createZipQueryInfoTaskV2
} from "@/utils/apiList/cloud";
import { shareSetInfo } from "@/utils/apiList/share";
import {
  bindPhoneSendCode,
  bindPhoneAPI,
  realNameAuth,
  getPopupStauts,
} from "@/utils/apiList/account";
import LoopText from "@/components/LoopText/index.vue";
import { turnSaveFile } from '@/utils/apiList/downpage';
export default {
  components: {
    LoopText,
    PromoteDialog,
    ShareDialog,
    BaseDialog,
    RenameFile,
    BindPhone,
    RecycleTips,
    FileDirDialog,
    PreviewPublic,
    TabList,
    QVirtualList,
    Riskverification,
    UpZipDialog,
    PressPwd,
    ShareMoreLastDialog,
    Electronpop2
  },
  data() {
    return {
      stopFn: null,
      curId: 0,
      fileType: [
        { id: 0, name: "cloud.all" },
        { id: 1, name: "cloud.package" },
        { id: 2, name: "cloud.medium" },
        { id: 3, name: "cloud.document" },
        { id: 4, name: "cloud.picture" },
      ],
      fileTypeId: 0,
      screenList: [
        { id: 3, id1: '', name: "按浏览量排序" },
        { id: 2, id1: '', name: "按下载量排序" },
        { id: 1, id1: '', name: "按大小排序" },
        { id: 0, id1: '', name: "按时间排序" },
      ],
      screenId: 0,
      filesList: [], //文件列表
      total: 0, //总条数 缺省判断
      allFileTotal: 0, //总条数 全部文件
      checkedFiles: [], //选中文件
      allCheck: 0,
      isShowPromote: false,
      vip_update: 1,
      isShowShareDialog: false,
      isShowBaseDialog: false,
      isFmAddHove: false,
      isShowInputDialog: false,
      isShowBindPhone: false,
      isShowTipDialog: false,
      isShowFileDir: false,
      shareTips:
        "为了共同维护良好的网络环境，每次分享的文件将会经过机器检测，请勿分享非法文件。",
      folderList: [], //文件夹目录
      routerList: [{ name: "全部文件", id: "0" }], //文件夹层级目录
      showPreviewPublic: false,
      loading: true,
      loadNextPage: false,
      inputType: 1, //1 新建文件夹 2修改文件夹名 3修改文件名
      rename: "",
      reextension: "",
      handleItem: {}, //当前操作项
      params: {
        //查询列表参数
        views:"",
        down: "", 
        folder_id: 0,
        keywords: "",
        page: 0,
        limit: 60,
        size: "",
        time: 1,
        type: 0,
      },
      dragoverIndex: -1,
      order: false,
      showboxBreadMove: false,
      moveNav: [],
      moveType: 1, //1单个 2多选移动
      delType: 2, //1单个 2多选删除
      boxType: '-1',
      shareUrl: "", //设置分享后返回的链接
      showTop: false,
      isOver: false,
      showEnd: true,
      docData: [],
      previewFileType: 1,
      myInterval: null,
      diskSize: {}, //云盘容量
      usedPre: 0,
      previewUrl: "",
      previewImgData: {},
      bindType: 1, //
      msgid: "", //验证码id
      isbindphone: "", //是否绑定手机号
      timerA: null,
      time: 60,
      user_id_card_status: "", //是否实名
      is_first_share: 1,
      auto_set: 0, //是否自动填充
      isSysCode: 1, // 1 默认系统 生成  2自定义
      loadDoc: false, //文档解析加载动画
      pucode: "",
      open_pucode: "",
      recycle_expire_day: "", //加入回收站过期天数
      recycle_tit: "",
      //----
      isSelecting: 0,
      startX: 0,
      startY: 0,
      selectionBox: {
        left: 0,
        top: 0,
        width: 0,
        height: 0,
      },
      moveBox: {
        left: 0,
        top: 0,
      },
      mouseDownIsCheck: false,
      checkStr: "",
      uploader: null,
      showFlag: 0,
      trage_ids: "",
      trage_move_ids: "",
      trage_folder_id: -1,
      isOpen: false,
      isOpen2: true,
      aMenuItem: [], //右键
      list1: [
        { id: 0, name: "cloud.rightOpen" },
        { id: 1, name: "cloud.rightDownload" },
        { id: 6, name: "cloud.pcDownfile" },
        { id: -1, name: "" },
        { id: 2, name: "cloud.rightShare" },
        { id: 3, name: "cloud.rightMoveto" },
        { id: -1, name: "" },
        { id: 4, name: "cloud.rightRename" },
        { id: 5, name: "cloud.rightDelete" },
      ],
      list2: [
        { id: 0, name: "cloud.rightOpen" },
        { id: -1, name: "" },
        { id: 3, name: "cloud.rightMoveto" },
        { id: -1, name: "" },
        { id: 4, name: "cloud.rightRename" },
        { id: 5, name: "cloud.rightDelete" },
      ],
      list3: [{ id: 5, name: "cloud.rightDelete" }],
      list4: [
        { id: 3, name: "cloud.rightMoveto" },
        { id: -1, name: "" },
        { id: 5, name: "cloud.rightDelete" },
      ],
      list5: [
        { id: 2, name: "cloud.rightShare" },
        { id: -1, name: "" },
        { id: 3, name: "cloud.rightMoveto" },
        { id: -1, name: "" },
        { id: 5, name: "cloud.rightDelete" },
      ],
      isSelectMore: false,
      promoteType: "",
      promoteMsg: "",
      uploaderPosition: "",
      dropdown: ["contextmenu"],
      downItem: {},
      downItem1: {},
      phone_item: {
        phone: "",
        pcode: "",
      },
      showRisk: -1, //0 是转存，1下载
      virtualDom: {
        inFirst: 0,
        itemSize: 56,
        offsetTop: 0,
        start: 0,
        count: 0,
      },
      expire: "",
      tooltipDisabled: false,
      oldIndex:-1,
      sc: "", //屏幕点击位置
      ms: "", //屏幕点击位置
      showUpZipDialog:false,
      showPressPwd:false,
      icon:'',
      zipPwd:'',
      upZipIng: false,
      incomeList: [],
      networkType: '4g',
      checkids: [],
      checkidsAllInfo: [],
      filePlaceholderTop:'cloud.filePlaceholderTop',
      isElectron2: false,
      iscode: '',
    };
  },
  created() {
    let appRightWidth =
      document.getElementById("app-bottom-right")?.clientWidth;

    this.$store.commit("setAttr", {
      name: "appRightWidth",
      val: appRightWidth,
    });
    this.getDiskCapacity();
    
    if (this.$route.params.folder_id || localStorage.getItem('openFolder')) {
      let openFolder = JSON.parse(localStorage.getItem('openFolder'))

      let _obj = {
        folder_id: this.$route.params.folder_id || openFolder.folder_id,
        folders: this.$route.params.folders || openFolder.folders,
      };
      this.getfilescallback({ value: _obj });
    } else {
      this.getFiles(1);
    }
    this.networkType = this.$utils.getConnection()?.effectiveType || '4g';
    
    window.addEventListener("mouseup", this.endSelection);
  },
  beforeDestroy() {
    window.removeEventListener("mouseup", this.endSelection);
  },
  watch: {
    checkedFiles: {
      handler(newval, oldVal) {
        if (newval.length > 0 && newval.length < this.filesList.length) {
          this.allCheck = 1;
        } else if (newval.length == 0) {
          this.allCheck = 0;
        } else if (newval.length == this.filesList.length) {
          this.allCheck = 2;
        }
        var ids = [];
        var checkidsAllInfo=[]
        newval.map((item) => {
          ids.push(this.filesList[item].id);
          checkidsAllInfo.push(this.filesList[item])
        
        });
        this.checkids = ids
        this.checkidsAllInfo = checkidsAllInfo

      },
      immediate: true,
    },
    pageValue(newVal) {
      if (newVal == 1) {
        this.cancelCheckedVal();
        this.scrollToTop();
      }
    },
    showPreviewPublic(newval) {
      const myElement = this.$refs.previewPublic;
      if (newval) {
        document.body.appendChild(myElement.$el);
      } else {
        document.body.removeChild(myElement.$el);
      }
    },
  },
  computed: {
    pageValue() {
      return this.params.page;
    },
  },
  beforeRouteLeave(to, from, next) {
    const myElement = this.$refs.previewPublic;
    if (
      myElement &&
      myElement.$el &&
      myElement.$el.parentNode === document.body
    ) {
      document.body.removeChild(myElement.$el);
    }
    this.showPreviewPublic = false;
    next();
  },
  mounted() {
    this.$store.commit("setAttr", {
      name: "getfilescallback",
      val: this.getfilescallback,
    });
    this.$store.commit("setAttr", {
      name: "closePreviewPubliccallback",
      val: this.closePreviewPublic,
    });
    this.$store.commit("setAttr", {
      name: "endSelectFuncallback",
      val: this.endSelectFun,
    });
      this.$store.commit("setAttr", {
          name: "handleDropEnd",
          val: this.dropEnd,
      });

    let Upload = this.$route.params.upload;
    if (Upload) {
      this.toUpload();
    }
    this.$utils.routeModeReported('B10');
  },
  methods: {
    handleClose2Fn(){
      if(this.promoteMsg == '请开通会员，享受文件在线解压') {
        this.$utils.routeModeReported('C17');
      } else {
        this.$utils.routeModeReported('C19');
      }
    },
    onGetVlBoxAttr(args) {
      let dom = args.vlBox;
      this.virtualDom.start = args.start;
      this.virtualDom.count = args.vlCount;
      this.virtualDom.offsetTop = dom.offsetTop;
      if (dom.offsetParent) {
        this.virtualDom.offsetTop += dom.offsetParent.offsetTop;
      }
      let appView = document.getElementById("app-view");
      if (appView) {
        this.virtualDom.offsetTop += appView.offsetTop;
      }
    },
    isHasLockFile() {
      let arr = this.checkedFiles.map((item) => {
        return this.filesList[item];
      });
      return arr?.some((item) => item.is_locked == "1");
    },
    closePreviewPublic() {
      const myElement = this.$refs.previewPublic;
      if (this.showPreviewPublic) {
        this.showPreviewPublic = false;
        if (myElement && myElement.$el) {
          document.body.removeChild(myElement.$el);
        }
      }
    },
    getfilescallback(opt) {
      if (opt) {
        //上传/解压 查看所在目录 调用
        this.params = {
          down: "", //排序
          folder_id: 0,
          keywords: "",
          page: 1,
          limit: 60,
          size: "",
          time: 1,
        };
        this.screenId = 0;
        this.fileTypeId = 0;
        if(this.$refs.tabList){
          this.$refs.tabList.keyword = "";
        }
        this.routerList = opt.value.folders.map((v) => ({
          id: v.folder_id,
          name: v.folder_name,
        }));
        this.params.folder_id = opt.value.folder_id;
      }
      this.checkedFiles = [];
      this.getFiles(1);
      this.getDiskCapacity();
    },
    setNav(url) {
      this.$router.push({
        path: url,
      });
    },
    chageClass(id) {
      this.fileTypeId = id;
      this.params.type = id;
      this.params.folder_id = 0;
      this.routerList = [{ name: "全部文件", id: "0" }];
      this.checkedFiles = [];
      this.getFiles(1);
    },
    //切换排序
    changeSort(id,resolve=false) {
      this.screenId = id;
      this.params.down = '';
      this.params.views = '';
      switch (id) {
        case 0:
         this.params.size = '';
          if (resolve) {
            if (this.params.time == 1) {
              this.params.time = 0;
            } else {
               this.params.time = 1;
            }
          } else {
             this.params.time = 1;
          }
          break;
        case 1:
          this.params.time = '';
          if (resolve) {
            if (this.params.size == 1) {
              this.params.size = 0;
            } else {
               this.params.size = 1;
            }
          } else {
            this.params.size = 1;
          }
          break;
        case 2:
          this.params.time = '';
          this.params.size = '';
          this.params.down = 1;
          break;
        case 3:
          this.params.time = '';
          this.params.size = '';
          this.params.views = 1;
          break;
        default:
          break;
      }
      this.checkedFiles = [];
      this.getFiles(1);
    },
    //排序
    sort(id, order) {
      this.order = !order;
      if (id == 0) {
        //按大小排序
        this.params.time = "";
        this.params.size = this.order ? 0 : 1;
      } else {
        //按时间排序
        this.params.time = this.order ? 0 : 1;
        this.params.size = "";
      }
      this.params.page = 0;
      this.checkedFiles = [];
      this.getFiles(1);
    },
    //刷新
    refresh() {
      this.params.page = 0;
      this.checkedFiles = [];
      this.getFiles(1);
    },
    //搜索
    searchList(value) {
      this.params.keywords = value;
      this.params.folder_id = 0;
      this.routerList = [{ name: "全部文件", id: "0" }];
      this.checkedFiles = [];
      this.getFiles(1);
    },
    scrollBoxFun(e) {
      this.showTop = e.sTop > 50;
      this.showEnd = e.distance <= 136;
      this.virtualDom.start = e.start;
      this.virtualDom.count = e.vlCount;
      let list = document.getElementsByClassName("el-tooltip__popper");
      let list1 = document.getElementsByClassName("el-dropdown-menu");
      if (list.length > 0) {
        list[list.length - 1].style.display = "none";
      }
      if (list1.length > 0) {
        list1[list1.length - 1].style.display = "none";
      }
    },
    scrollToTop() {
      this.$refs.virtualBox?.scrollToTop();
    },
    //获取云盘容量
    getDiskCapacity: _.debounce(
      function(){
      diskCapacity().then((res) => {
        if (res.status == "1") {
          this.diskSize = res.data;
          this.vip_update = res.data.vip_update;
          if (res.data.cloud_size == "999999") {
            // ∞
            this.usedPre = 0;
          } else {
            this.usedPre = (res.data.self_size / res.data.cloud_size) * 100;
          }
        }
      });
    },50,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    //获取列表
    getFiles: _.debounce(
      function (type = 0) {
        if (type == 1) {
          this.loading = true;
          this.params.page = 0;
        } else {
          if (this.isOver) {
            return;
          }
          this.loadNextPage = true;
        }
        this.params.page += 1;
        this.isOver = false;
        getCloudList(this.params)
          .then((res) => {
            if (res.status == "1") {
              this.loading = false;
              this.loadNextPage = false;
              this.total = parseInt(res.data.total);
              if (this.params.folder_id == 0) {
                this.allFileTotal = parseInt(res.data.total); //全部文件旁边使用的总数
              }
              if (res.data.list.length < this.params.limit) this.isOver = true;
              res.data.list.forEach((item) => {
                item.isHover = false;
                item.active = false;
                item.isShowEdit = false;
                item.showPlay = true;
              });
              if (this.params.page == 1) {
                this.filesList = res.data.list || [];
              } else {
                this.filesList = this.filesList.concat(res.data.list).filter((item, index, self) => 
                  index === self.findIndex((t) => t.id === item.id)
                );
              }
              (this.isbindphone = res.data.isbindphone),
                (this.user_id_card_status = res.data.user_id_card_status);
              this.is_first_share = res.data.is_first_share;
              this.$store.commit("setAttr", {
                name: "today_upload_num",
                val: res.data.today_upload_num,
              });
              if (
                this.checkedFiles.length > 0 &&
                this.checkedFiles.length < this.filesList.length
              ) {
                this.allCheck = 1;
              } else if (this.checkedFiles.length == 0) {
                this.allCheck = 0;
              } else if (this.checkedFiles.length == this.filesList.length) {
                this.allCheck = 2;
              }
              localStorage.removeItem('openFolder')
            } else {
              this.loading = false;
              this.$toast(res.msg, 2);
            }
            if (this.params.page == 1) {
                this.$nextTick(() => {
                    this.scrollToTop()
                })
            }
          })
          .catch((err) => {});
      },
      50,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    // 重置查询
    resetRouter() {
      this.params = {
        down: "", //排序
        folder_id: 0,
        keywords: "",
        page: 1,
        limit: 60,
        size: "",
        time: 1,
      };
      this.checkedFiles = [];
      this.fileTypeId = 0;
      this.$refs.tabList.keyword = "";
      this.getFiles(1);
    },
    //打开文件夹
    openFolder(id, name) {
      this.allCheck = 0;
      this.checkedFiles = [];
      this.routerList.push({ id: id, name: name });
      this.params.folder_id = id;
      this.params.page = 0;
      this.params.keywords = "";
      this.$refs.tabList.keyword = "";
      this.getFiles(1);
    },
    //点击顶部文件夹路径-跳出当层文件夹
    toUp(item, index) {
      let arr = this.routerList.slice(0, index + 1);
      this.routerList = arr;
      this.params.folder_id = item.id;
      this.params.page = 0;
      this.checkedFiles = [];
      this.params.keywords = "";
      this.$refs.tabList.keyword = "";
      this.showboxBreadMove = false;
      this.getFiles(1);
      this.scrollToTop();
    },
    //打开新建/重名文件弹框
    openInputDialog(type, item) {
      this.inputType = type;
      this.isShowInputDialog = true;
      this.$refs.RenameFile.rename = item ? item.name : "";
      this.reextension = item ? item.extension : "";
    },
    handleName(value) {
      if (this.inputType == 3) {
        this.fileReName(value);
      } else {
        this.modiFolder(value);
      }
    },
    //创建/重命名文件夹接口
    modiFolder(value) {
      let params = {
        name: value,
        pid: this.params.folder_id,
        id: this.inputType == 1 ? "" : this.handleItem.id,
      };
      modiFolder(params)
        .then((res) => {
          if (res.status == "1") {
            this.checkedFiles = [];
            this.$toast(res.msg, 1);
            this.isShowInputDialog = false;
            this.getFiles(1);
          } else {
            this.$toast(res.msg, 2);
          }
        })
        .catch((err) => {});
    },
    // 上传
    toUpload(type = "file") {
      if (parseFloat(this.diskSize.self_size) > this.diskSize.cloud_size) {
        this.isShowPromote = true;
        this.promoteMsg =
          this.vip_update == "1"
            ? "您的云盘空间已满，请升级会员继续使用"
            : "您的云盘空间已满";
        this.promoteType = 3;
        this.icon='icon_space';
        return;
      }
      if (this.$store.state.userInfo.phone == "") {
        this.bindType = 1;
        this.isShowBindPhone = true;
        return;
      }
      if (type === "folder") {
        this.$refs.selectFolderLabel.click();
      } else {
        this.$store.commit("selectfilecallback");
      }
      let id = this.routerList[this.routerList.length - 1].id;
      let name = this.routerList[this.routerList.length - 1].name;
      this.$store.commit("setUploadFloder", { id, name });
      this.isFmAddHove = false;
    },
    // 单个点击选中
    chooseFile(index) {
      // 点击文件选中
      var exist = this.checkedFiles.indexOf(index);
      if (exist == -1) {
        this.checkedFiles.push(index);
      } else {
        this.checkedFiles.splice(exist, 1);
      }
    },
    // 全选
    setCheckedVal() {
      // 点击文件选中
      this.checkedFiles = [];
      var tmpfiles = this.filesList;
      for (var i = 0, len = tmpfiles.length; i < len; i++) {
        this.checkedFiles.push(i);
      }
      this.allCheck = 1;
    },
    // 取消全选
    cancelCheckedVal() {
      this.allCheck = 0;
      this.checkedFiles = [];
    },
    //下拉框出现/隐藏时触发
    changeVisible(e, item) {
      item.isHover = e;
    },
    //更多 操作 a移动 b重命名 c删除
    handleCommand: _.debounce(
      function (command, item) {
        this.handleItem = item;
        if (command == "a") {
          this.moveType = 1;
          this.getFolderMoveList();
        } else if (command == "b") {
          let fileIndex = this.filesList.findIndex((v) => v.id === item.id);
          if(this.oldIndex >=0 ){  
            this.$set(this.filesList[this.oldIndex], "isShowEdit", false);
          }
          this.oldIndex = fileIndex;
          if (fileIndex >= 0) {
            this.$set(this.filesList[fileIndex], "isShowEdit", true);
          }
          this.rename = item.name;
          this.$nextTick(() => {
            let _input = this.$refs.virtualBox.$el.querySelector(
              "#upload-" + item.id + " input"
            );
            if (_input) {
              _input.select();
            }
          });
        } else {
          this.checksMoveDel(1);
        }
      },
      500,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    //右键操作
    rightHandle(e,id, item) {
      this.visibleChange(false);
      if (id == 0) {
        item.is_file == "0"
          ? this.openFolder(item.id, item.name)
          : this.previewFile(item);
      } else if (id == 1) {
        if (e) {
          let _event = e.domEvent;
          this.ms = _event.clientX + "*" + _event.clientY;
          this.sc = window.screen.width + "*" + window.screen.height;
        }
        this.goDownload("",item);
      } else if (id == 2) {
        this.goShare(item);
      } else if (id == 3) {
        //移动
        this.checkedFiles.length > 0
          ? this.checksMove()
          : this.handleCommand("a", item);
      } else if (id == 4) {
        //重命名
        this.handleCommand("b", item);
      } else if(id == 6) {
        this.electronFn2(item)
      } else {
        //删除
        this.checkedFiles.length > 0
          ? this.checksMoveDel(2)
          : this.handleCommand("c", item);
      }
    },
    //移动文件夹目录列表  移动弹窗-点击文件夹-进入里层文件夹
    getFolderMoveList(folder) {
      if (folder) {
        this.moveNav.push(folder);
      } else {
        this.moveNav = [{ name: "全部文件", id: "0" }];
      }
      let params = {
        id: folder ? folder.id : 0,
      };
      folderMoveList(params)
        .then((res) => {
          if (res.status == "1") {
            this.folderList = res.data.list;
            this.isShowFileDir = true;
          } else {
            this.$toast(res.msg, 2);
          }
        })
        .catch((err) => {});
    },
    // 移动弹窗-点击顶部文件夹路径-跳出当层文件夹
    changeNav(item, index) {
      let arr = this.moveNav.slice(0, index + 1);
      this.moveNav = arr;
      folderMoveList({ id: item.id })
        .then((res) => {
          if (res.status == "1") {
            this.folderList = res.data.list;
            this.isShowFileDir = true;
          } else {
            this.$toast(res.msg, 2);
          }
        })
        .catch((err) => {});
    },
    // 多选移动
    checksMove() {
      this.moveType = 2;
      this.getFolderMoveList();
    },
    //文件移动
    doFileMove() {
      var folder_id =
        this.moveNav.length > 0 ? this.moveNav[this.moveNav.length - 1].id : 0;
      let ids = "";
      let folder_move_ids = "";
      if (this.moveType == 1) {
        if (this.handleItem.is_file == "1") {
          ids = this.handleItem.id;
        } else {
          folder_move_ids = this.handleItem.id;
        }
      } else {
        let file_arr = [];
        let folder_arr = [];
        this.checkedFiles.map((item) => {
          if (this.filesList[item].is_file == "1") {
            file_arr.push(this.filesList[item].id);
          } else {
            folder_arr.push(this.filesList[item].id);
          }
        });
        ids = file_arr.toString();
        folder_move_ids = folder_arr.toString();
      }
      let params = {
        ids: ids, //文件id
        folder_move_ids: folder_move_ids, //文件夹id
        folder_id: folder_id,
      };
      fileMove(params)
        .then((res) => {
          if (res.status == "1") {
            this.$toast(res.msg, 1);
            this.isShowFileDir = false;
            this.checkedFiles = [];
            this.cancelCheckedVal();
            this.params.page = 1;
            this.getFiles(1);
          } else {
            this.$toast(res.msg, 2);
          }
        })
        .catch((err) => {});
    },
    // 获取选中的文件的id
    getSelFileIds() {
      var ids = [];
      this.checkedFiles.map((item) => {
        ids.push(this.filesList[item].id);
      });
      return this.$utils.arrFilter(ids);
    },
    //多选、单选删除
    checksMoveDel(type) {
      this.delType = type;
      this.getExpireDay();
    },
    //获取回收站过期时间
    getExpireDay() {
      getExpireDay().then((res) => {
        if (res.status == "1") {
          this.recycle_expire_day = res.data.recycle_expire_day;
          if (this.delType == 1) {
            this.recycle_tit =
              this.handleItem.is_file == "1" ? "文件" : "文件夹";
          } else {
            let arr = this.checkedFiles.map((item) => {
              return this.filesList[item];
            });
            this.recycle_tit = arr.some((item) => item.is_file == "0")
              ? "文件夹"
              : "文件";
          }
          this.isShowTipDialog = true;
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
    //确认删除
    doDel() {
      if (this.delType == 1) {
        if (this.handleItem.is_file == "0") {
          //文件夹
          this.recycleFile({ folder_id: this.handleItem.id });
        } else {
          this.recycleFile({ file_ids: this.handleItem.id });
        }
      } else {
        var ids = [];
        var ids2 = [];
        this.checkedFiles.map((item) => {
          if (this.filesList[item].is_file == "0") {
            ids.push(this.filesList[item].id);
          } else {
            ids2.push(this.filesList[item].id);
          }
        });
        var folder_id = this.$utils.arrFilter(ids).toString();
        var file_ids = this.$utils.arrFilter(ids2).toString();
        this.recycleFile({ folder_id: folder_id, file_ids: file_ids });
      }
    },
    //加入回收站
    recycleFile(params) {
      recycleFile(params).then((res) => {
        if (res.status == "1") {
          if (
            this.showPreviewPublic &&
            this.previewFileType == 2 &&
            (this.previewImgData.next != 0 || this.previewImgData.prev != 0)
          ) {
            this.previewImg(
              this.previewImgData.next != 0
                ? this.previewImgData.next
                : this.previewImgData.prev
            );
          } else {
            this.showPreviewPublic = false;
          }
          this.isShowTipDialog = false;
          this.openMessage(res.data.recycle_id);
          this.cancelCheckedVal();
          this.params.page = 1;
          this.getFiles(1);
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
    //修改文件名
    fileReName(value) {
      fileReName({ id: this.handleItem.id, name: value }).then((res) => {
        if (res.status == "1") {
          this.$toast(res.msg, 1);
          this.isShowInputDialog = false;
          this.handleItem.name = res.data.name;
          this.params.page = 1;
          this.getFiles(1);
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
    //分享
    goShare(item = {}) {
     const hasDisallowedShare = this.checkidsAllInfo.some((item) => item.is_allowed_share == 0);
      if (hasDisallowedShare) {
        this.$toast("所选分享文件包含音视频类型，暂不支持分享", 2);
        return;
      }
      this.handleItem = item;
      if (this.$store.state.userInfo.phone == "") {
        this.bindType = 1;
        this.isShowBindPhone = true;
        return;
      }
      getPopupStauts({ popup_id: 100 }).then((res) => {
        if (res.status == "1") {
          if (res.data.is_open == "1") {
            this.isShowBaseDialog = true;
          } else {
            this.openShareDialog();
          }
        }
      });
    },
    openShareDialog() {
      this.isShowBaseDialog = false;
        //获取是否自动填充 和 取件码选择
        shareSetInfo({ file_id:this.checkids.length>0 ? this.checkids.toString() : this.handleItem.id }).then((res) => {
          if (res.status == "1") {
            if (this.checkids.length <= 1) { 
              if (this.handleItem.isshare == 0 ) { 
              this.auto_set = parseInt(res.data.auto_set);
              this.isSysCode = res.data.pucode == "" ? 1 : 2; //空就是系统的
              this.$refs.shareDialog.pucode = res.data.pucode;
              this.open_pucode = res.data.open_pucode;
              this.incomeList = res.data.rebate_model;
              this.boxType = '1';
            } else {
              this.shareUrl = this.handleItem.share_link;
              this.boxType = '2';
              this.incomeList = res.data.rebate_model;
            }
            } else {
             this.auto_set = parseInt(res.data.auto_set);
              this.isSysCode = res.data.pucode == "" ? 1 : 2; //空就是系统的
              this.$refs.shareDialog.pucode = res.data.pucode;
              this.open_pucode = res.data.open_pucode;
              this.incomeList = res.data.rebate_model;
              this.boxType = '1';
            }
            this.isShowShareDialog = true;
          }else{
            this.$toast(res.msg,2)
          }
        });
    },
    //设置分享
    setShare: _.debounce(
      function (params) {
          if (this.checkids.length > 1) {
            params.file_ids = params.file_id;
            delete params.file_id;
          }
          if (this.checkids.length <= 1) { 
            setShare(params).then((res) => {
              if (res.status == "1") {
                this.boxType = '2';
                this.$refs.shareDialog.pucode = "";
                this.shareUrl = res.data.link;
                this.handleItem.good_count = res.data.good_count;
                this.getFiles(1);
                if (this.showPreviewPublic) {
                  this.handleItem.isshare = 1;
                  this.handleItem.share_link = res.data.link;
                }
                this.$toast(res.msg, 1);
                
              } else {
                this.$toast(res.msg, 2);
              }
            });
          } else {
            setBatchSetShare(params).then((res) => {
              if (res.status == "1") {
                this.isShowShareDialog = false;
                this.$refs.ShareMoreLastDialog.linkList = res.data.link_list;
                this.$refs.ShareMoreLastDialog.linkStr = res.data.link_str;
                this.$refs.ShareMoreLastDialog.isShow = true;
                this.getFiles(1);
                this.$toast(res.msg, 1);
              } else {
                this.$toast(res.msg, 2);
              }
            });
          }
      },
      100,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    //预览
    previewFile(item) {
      if (this.$store.state.userInfo.phone == "") {
        this.bindType = 1;
        this.isShowBindPhone = true;
        return;
      }
      this.handleItem = item;
      // this.checkedFiles = [];
      if(item.is_office_preview=='0'){
        this.previewFileType = 10;
        this.showPreviewPublic = true;
      }else{
        if (item.ext == "txt") {
          this.previewFileType = 8;
          this.showPreviewPublic = true;
          this.$refs.previewPublic.previewTxt(this.handleItem.id)
        } else if (item.ext == "zip") {
          this.openZip(this.handleItem.id)

          // this.$refs.UpZipDialog.queryZipInfoTask(this.handleItem.id)
          // this.showUpZipDialog = true;
          // this.previewFileType = 1;
          // this.showPreviewPublic = true;
        } else if (item.ext == "png") {
          this.previewFileType = 2;
          this.previewImg(this.handleItem.id);
        } else if (item.ext == "mp3") {
          this.previewFileType = 3;
          this.previewAudiovisual();
        } else if (item.ext == "mp4") {
          this.previewFileType = 4;
          this.previewAudiovisual();
        } else if (item.ext == "xls") {
          this.previewFileType = 9;
          this.previewAudiovisual();
        } else if (/^doc|pdf|ppt$/.test(item.ext)) {
          this.createDocTask(item); //预览doc
        } else {
          this.previewFileType = 10;
          this.showPreviewPublic = true;
        }
      }
    },
    closeZip(){
      this.showUpZipDialog = false;
      this.zipPwd = '';
      this.$refs.pressPwd.value = '';
    },
    //打开zip
    openZip: _.debounce(
      function (id,act=0) {
        let vipList = this.$store.state.userInfo.vips;
        if(act == 0){
          if(vipList.length > 0 ){
              let exists = vipList.some((v) => v.vip_id == '1039');
              if(exists){
                this.$toast('尊贵的不限时长会员，正为您在线打开',1)
              }else{
                this.$toast('尊贵的会员，正为您在线打开',1)
              }
          }else{
              this.icon = "icon_diamond";
              this.promoteType = 3;
              this.promoteMsg = '请开通会员，享受文件在线解压'
              this.isShowPromote = true;
              this.vip_update = '2';
              return;
          }
        }
        let params = {
          file_id:id,
          path: '',
          password: this.zipPwd,
        };
        this.icon = '';
        this.loading = true;
        this.upZipIng = true;
        //打开新的解压时关闭
        this.$store.commit("setAttr", {
          name: "showUpDownBox",
          val: 0,
        });
        this.$store.commit("setAttr", {
          name: "shutdown",
          val: true,
        });
        createZipQueryInfoTaskV2(params).then((res) => {
          this.loading = false;
          this.upZipIng = false;
          if (res.status == "1") {
            this.showPressPwd = false;
            this.showUpZipDialog = true;
            this.$refs.UpZipDialog.zipFileList = res.data.list;
            this.$refs.UpZipDialog.allTotalNum = res.data.fileTotal;
          } else if (res.status == "30001") {
            this.showPressPwd = true;
          }else if(res.status == "30002"){//解压密码错误
            this.$toast(res.msg, 2);
          } else if (res.status == "5000") {
            this.$toast(res.msg, 2);
          } else if (res.status == "82000") {//解压非会员提示
            this.icon = "icon_diamond";
            this.promoteType = 3;
            this.promoteMsg = res.msg
            this.isShowPromote = true;
            this.vip_update = '2';
          } else {
            this.$toast(res.msg, 2);
          }
       });
      },
      500,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    confirmPwd(pwd) {
      if(pwd==''){
        this.$toast('解压密码不能为空', 2);
        return
      }
      this.zipPwd = pwd;
      this.openZip(this.handleItem.id,1);
    },
    closePwdBox(){
      this.zipPwd=''
      this.showPressPwd=false
    },
    // 预览execl、视频 音频
    previewAudiovisual() {
      previewAudiovisual({ file_id: this.handleItem.id }).then((res) => {
        if (res.status == "1") {
          this.expire = parseInt(res.data.expire);
          if (this.previewFileType == 4) {
            var option = {
              url: res.data.url,
              theme: "#f67942",
              playbackRate: true,
              setting: true,
              pip: true,
              fullscreen: true,
              // autoSize: true,
              expire:this.expire
            };
            this.showPreviewPublic = true;
            this.$nextTick(() => {
              this.$refs.previewPublic.loadVideo(option);
            });
          } else {
            this.previewUrl = res.data.url;
            this.showPreviewPublic = true;
            if (this.previewFileType == 3) {
              this.$nextTick(() => {
                this.$refs.previewPublic.play();
              });
            }
          }
        } else if (res.status == "10021") {
          this.$toast(res.msg, 2);
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
    //创建预览任务 word,ppt,pdf
    createDocTask: _.debounce(
      function (item) {
        previewTask({ file_id: item.id }).then((res) => {
          if (res.status == "1") {
            this.loadDoc = true;
            this.previewFileType =
              item.ext == "pdf" ? 6 : item.ext == "doc" ? 7 : 5;
            this.showPreviewPublic = true;
            this.myInterval = setInterval(() => {
              this.officeDocument(res.data.time, item);
            }, 1000);
          } else {
            this.$toast(res.msg, 2);
          }
        });
      },
      500,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    //word,ppt,pdf 的文件预览
    officeDocument(time, item) {
      //预览类型 1:pdf 2:word 3:ppt
      const type = item.ext == "pdf" ? 1 : item.ext == "doc" ? 2 : 3;
      officeDocument({ time: time, file_id: item.id, preview_type: type }).then(
        (res) => {
          if (res.status == "1") {
            clearInterval(this.myInterval);
            this.loadDoc = false;
            if (item.ext == "doc" && res.data.office_type == "2") {
              this.$refs.previewPublic.viewDocx(res.data.file_url);
              return;
            }
            this.docData = res.data.imgs;
          } else if (res.status != "66001") {
            clearInterval(this.myInterval);
            this.loadDoc = false;
            this.$refs.previewPublic.fileLoadMsg = res.msg;
          }
        }
      );
    },
    closePreview() {
      clearInterval(this.myInterval);
      this.previewImgData = {};
      this.showPreviewPublic = false;
      this.loadDoc = false;
      this.docData = "";
      // this.getFiles(1);
    },
    //预览图片
    previewImg(id) {
      previewImg({ file_id: id, ...this.params }).then((res) => {
        if (res.status == "1") {
          this.previewImgData = res.data;
          this.showPreviewPublic = true;
          this.handleItem = res.data.file;
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },

    getPhoneAly(captcha_verify) {
      return new Promise((resolve) => {
        bindPhoneSendCode({
          phone: this.phone_item.phone,
          pcode: this.phone_item.pcode,
          captcha_verify: captcha_verify || "",
        }).then((res) => {
          if (res.status == "1") {
            resolve({
              result: true,
              validate: true,
            });
            this.$toast(res.msg, 1);
            this.msgid = res.data.msgid;
            this.bindType = 3;
            this.timerA = setInterval(() => {
              this.minute();
            }, 1000);
          } else if (res.status == 90001) {
            resolve({
              result: false,
              validate: false,
            });
            this.$toast(res.msg, 2);
            this.$AliCode.handleOpen(this.getPhoneAly);
          } else {
            resolve({
              result: true,
              validate: true,
            });
            this.$toast(res.msg, 2);
          }
        });
      });
    },
    //发送验证码
    getPhoneCode(phone, pcode) {
      this.phone_item = {
        phone,
        pcode,
      };
      // var reg_tel =
      //   /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      // if (!reg_tel.test(phone)) {
      //   this.$toast("请正确输入手机号", 3);
      //   return true;
      // }
      this.getPhoneAly();
    },
    closeBindPhone() {
      this.time = 60;
      this.timerA && clearInterval(this.timerA);
      this.isShowBindPhone = false;
    },
    minute() {
      this.time = this.time - 1;
      if (this.time == 0) {
        this.time = 60;
        clearInterval(this.timerA);
      }
    },
    bindPhone(data) {
      let params = { msgid: this.msgid, ...data };
      bindPhoneAPI(params).then((res) => {
        if (res.status == "1") {
          this.isShowBindPhone = false;
          this.$store.commit("getuserVuex");
          this.$toast(res.msg, 1);
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
    realNameAuth(data) {
      realNameAuth(data).then((res) => {
        if (res.status == "1") {
          this.isShowBindPhone = false;
          this.$store.commit("getuserVuex");
          this.$toast(res.msg, 1);
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
    downloadFile(url, fileName){
      fetch(url, {
        mode: 'cors',
      }).then(res => res.blob())
        .then(blob => {
          const blobUrl = window.URL.createObjectURL(blob)
          this.saveFile(blobUrl, fileName)
          window.URL.revokeObjectURL(blobUrl)
        })
    },
    downloadFile2(url, name){
      // 新窗口打开下载
      var domA = document.createElement('a')
      domA.setAttribute('id', 'aDown')
      domA.setAttribute('href', url)
      domA.setAttribute('download', name)
      domA.setAttribute('target', '')
      document.body.appendChild(domA)
      document.getElementById('aDown').click()
    },
    downloadFile3(url, filename){
      const fileStream = StreamSaver.createWriteStream(filename);
      fetch(url, {
        method: 'GET',
        cache: 'no-cache',
        headers: {}
      }).then(res => {
        const readableStream = res.body
        if (window.WritableStream && readableStream.pipeTo) {
          return readableStream.pipeTo(fileStream)
              .then(() => console.log('done writing'))
        }
        window.writer = fileStream.getWriter()
        const reader = res.body.getReader()
        const pump = () => reader.read()
            .then(res => res.done
                ? window.writer.close()
                : window.writer.write(res.value).then(pump))
        pump()
      })
    },
    saveFile(href, filename) {
      const a = document.createElement('a')   
      a.download = filename
      a.href = href
      a.target="_blank"
      document.body.appendChild(a)  
      a.click()
      a.remove()
    },
    //下载
    goDownloadAlagain(captcha_verify) {
      return new Promise((resolve) => {
        let params = {
          type: 1, // 1:列表下载，2:福利点下载，3:vip下载，4：重试
          code: this.downItem.fshort,
          captcha_verify: captcha_verify || "",
          accessid: this.downItem.accessid,
          ms: this.ms,
          sc: this.sc,
          network: this.networkType,
        };
        let item = this.downItem;
        downloadFile(params).then((res) => {
          if (res.status == "1") {
            resolve({
              result: true,
              validate: true,
            });
            let fileName = `${item.name}${item.extension}`;
            this.$store.commit("getDownloadFile", {
              name: "query",
              value: {
                fid: item.id,
                url: res.data.url,
                down_token: res.data.down_token,
                is_remote_record: res.data.is_remote_record || '',
                fileName: fileName,
                fileSize: item.size, //文件大小
                fileType: item.extension,
                down_id: res.data.down_id,
                down_label:res.data.down_label,
                title:
                  this.$store.state.userInfo.is_vip == "1"
                    ? "会员极速下载通道"
                    : "普通下载通道",
                isClickFlag:res.data.is_up,
                is_vip_down:res.data.is_vip_down,
              },
            });
            this.$store.commit("setAttr", {
              name: "showUpDownBox",
              val: 1,
            });
            this.$store.commit("setAttr", {
              name: "navAct",
              val: 1,
            });
          } else if (res.status == "40001") {
            //未绑定手机号
            this.bindType = 1;
            this.isShowBindPhone = true;
          } else if (res.status == "50006") {
            //被锁定
            this.$toast(res.msg, 2);
          } else if (res.status == 90001) {
            resolve({
              result: false,
              validate: false,
            });
            this.$toast(res.msg, 2);
            this.$AliCode.handleOpen(this.goDownloadAlagain);
          } else if (res.status == "90101") {
            this.showRisk = 1;
            this.$refs.Riskver.open();
          } else {
            resolve({
              result: true,
              validate: false,
            });
            this.$toast(res.msg, 2);
          }
        });
      });
    },
    goDownload(e,item, accessid = "") {
      if (e) {
        e = e || window.event;
        this.ms = e.clientX + "*" + e.clientY;
        this.sc = window.screen.width + "*" + window.screen.height;
      }
      this.downItem = item;
      if (accessid) {
        this.downItem = { ...this.downItem, accessid };
      }
      this.goDownloadAlagain();
    },
    electronFn2(item) {
      this.iscode = item.fshort
      this.isElectron2 = true;
    },
    openMessage(recycle_id) {
      const h = this.$createElement;
      this.$message({
        type: "success",
        duration: 3000,
        message: h(
          "div",
          {
            style:
              "padding-right:20px display: flex;align-items: center;color:#00B42A",
          },
          [
            h("span", null, "文件已放入回收站 "),
            h(
              "span",
              {
                style:
                  "position:absolute;top:50%;transform: translate(0, -50%);right: 15px;cursor:pointer",
                on: {
                  click: () => {
                    this.goToRenew(recycle_id);
                  },
                },
              },
              "撤销"
            ),
          ]
        ),
      });
    },
    goToRenew: _.debounce(
      function (recycle_id) {
        restoreFile({ recycle_ids: recycle_id }).then((res) => {
          if (res.status == "1") {
            this.checkedFiles = [];
            this.getFiles(1);
            this.$message.closeAll(); //关闭message弹窗
          } else {
            this.$toast(res.msg, 2);
          }
        });
      },
      500,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    hebingTest() {
      hebing({}).then((res) => {
        this.$toast("操作成功", 1);
        this.getFiles(1);
      });
    },
    handleMouseDown: _.debounce(
      function (event, index) {
        this.selectionBox = {
          left: 0,
          top: 0,
          width: 0,
          height: 0,
        };

        this.startX = event.clientX;
        this.startY = event.clientY;

        const currentX = event.clientX;
        const currentY = event.clientY;
        this.moveBox = {
          left: 0,
          top: 0,
        };
        // 检查触发事件的元素是否是 file-item-hover 的父元素
        if (event.button == 0) {
          if (this.filesList[index]?.isShowEdit) {
            let _input = this.$refs.virtualBox.$el.querySelector(
              "#upload-" + this.filesList[index].id + " input"
            );
            if (_input) {
              _input.setSelectionRange(
                this.filesList[index].name.length,
                this.filesList[index].name.length
              );
              _input.scroll(9999, 0);
            }
            return;
          }
          let arr = [
            "icon",
            "name",
            "icon_checks",
            "el-image__inner",
            "iconColor"
          ];
          if (!arr.includes(event.target.classList[0])) {
            //点击icon_checks 即左侧选中多选不触发handleMouseDown 只触发chooseFile
            // 执行你的 mousedown 逻辑
            this.selectItemOne(index);
            this.moveBox = {
              left: currentX,
              top: currentY - 56,
            };
            // if(this.filesList.length > 0){
            //   this.filesList[0].isShowEdit = false;
            // }
          }
          this.isOpen = false;
        } else if (event.button == 1) {
        } else if (event.button == 2) {
          var exist = this.checkedFiles.indexOf(index);
          if (exist == -1) {
            this.checkedFiles = [];
            index != -1 ? this.checkedFiles.push(index) : "";
          } else {
          }
          if (this.checkedFiles.length > 1) {
            this.isSelectMore = true;
          } else {
            this.isSelectMore = false;
          }
        }
      },
      100,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    handleMouseenter(event, index, id = -100) {
      this.trage_folder_id = -1;
      if (this.isSelecting != 2) return;
      let arr = this.filesList.filter(
        (item, index1) => index1 == index && item.is_file == 0
      );
      if (id != -100) {
        this.trage_folder_id = id;
      } else {
        this.trage_folder_id = arr.length > 0 ? arr[0].id : "-1";
      }
    },
    selectItemOne(index) {
      // 点击文件选中
      if (index == -1) {
        this.isSelecting = 1;
        return;
      }
      var exist = this.checkedFiles.indexOf(index);
      if (exist == -1) {
        this.checkedFiles = [];
        this.checkedFiles.push(index);
        this.isSelecting = 1;
      } else {
        this.isSelecting = 2;
        // this.checkedFiles.splice(exist, 1);
      }
    },
    selectItems: _.debounce(
      function (event) {
        const currentX = event.clientX;
        const currentY = event.clientY;
        if (this.isSelecting == 2) {
          this.moveBox = {
            left: currentX,
            top: currentY - 56,
          };
          let file_arr = [];
          let folder_arr = [];
          let chooseOne = this.filesList[this.checkedFiles[0]];

          let str = `${chooseOne.name}${
            chooseOne.extension || ""
          }`;
          
          let obj = {
            icon:chooseOne.is_file == '0' ? chooseOne.ext_icon : chooseOne.showPlay ? chooseOne.ext_icon : this.$utils.fileIcon(chooseOne.extension),
            name: str,
            count: this.checkedFiles.length,
          };
          // this.checkStr = str;
          this.$store.commit("setAttr", {
            name: "check_str",
            val: obj,
          });

          this.checkedFiles.map((item) => {
            if (this.filesList[item].is_file == "1") {
              file_arr.push(this.filesList[item].id);
            } else {
              folder_arr.push(this.filesList[item].id);
            }
          });
          this.trage_ids = file_arr.toString();
          this.trage_move_ids = folder_arr.toString();
          this.showFlag = 2;
          this.$store.commit("setAttr", {
            name: "showFlag",
            val: 2,
          });
        } else if (this.isSelecting == 1) {
          this.showFlag = 1;
          this.$store.commit("setAttr", {
            name: "showFlag",
            val: 1,
          });
          // 计算选择框的位置和大小
          const minX = Math.min(this.startX, currentX);
          const minY = Math.min(this.startY, currentY);
          const maxX = Math.max(this.startX, currentX);
          const maxY = Math.max(this.startY, currentY);
          this.selectionBox = {
            left: minX,
            top: minY - 56,
            width: maxX - minX,
            height: maxY - minY,
          };
          // 遍历可选择项，判断是否在选择框内并选中
            let checkedList = []
          let _start = this.virtualDom.start;
          const itemElement =
            this.$refs.virtualBox.$el.querySelectorAll(".file-item-hover");
          if (itemElement && itemElement.length > 0) {
            for (let i = 0; i < itemElement.length; i++) {
              let _index = _start + i;
              const itemRect = itemElement[i].getBoundingClientRect();
              if (itemRect.top > minY - 56 && itemRect.bottom < maxY + 39) {
                if (!checkedList.includes(_index)) {
                    checkedList.push(_index);
                }
              } else {
                const index = checkedList.indexOf(_index);
                if (index !== -1) {
                    checkedList.splice(index, 1);
                }
              }
            }
              this.checkedFiles = checkedList
          }
        }
      },
      0,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    endSelection(e) {
      e.preventDefault();
      if (this.isSelecting == 2 && this.trage_folder_id > -1) {
        let params = {
          ids: this.trage_ids, //文件id
          folder_move_ids: this.trage_move_ids, //文件夹id
          folder_id: this.trage_folder_id,
        };

        fileMove(params)
          .then((res) => {
            if (res.status == "1") {
              this.$toast(res.msg, 1);
              this.checkedFiles = [];
              this.cancelCheckedVal();
              this.trage_move_ids = "";
              this.trage_ids = "";
              this.getFiles(1);
            }else if(res.status == "50006"){//锁定
              this.$toast(res.msg, 2);
            } else {
              
            }
          })
          .catch((err) => {});
      }else{
        this.onFileDragleave('',-1)
      }
      this.isSelecting = 0;
      this.checkStr = "";
      this.showFlag = 0;
      this.$store.commit("setAttr", {
        name: "showFlag",
        val: 0,
      });
    },
    endSelectFun() {
      setTimeout(() => {
        this.isSelecting = 0;
        this.checkStr = "";
        this.showFlag = 0;
        this.$store.commit("setAttr", {
          name: "showFlag",
          val: 0,
        });
        this.$store.commit("setAttr", {
          name: "check_str",
          val: "",
        });
      }, 0);
    },
    onFileDrop(e) {
      let list = [];
      let domFiles = e.dataTransfer.files;
      if (domFiles.length < 1) {
        return false;
      }

      let items = e.dataTransfer.items;

      function scanFiles(item, path, index) {
        return new Promise((resolve) => {
          if (item.isFile) {
            item.file((fileData) => {
              fileData.fullPath = path + "/" + fileData.name;
              list[index].list.push(fileData);
              resolve();
            });
          } else if (item.isDirectory) {
            let directoryReader = item.createReader();

            directoryReader.readEntries((entries) => {
              Promise.all(
                entries.map((item) => {
                  return scanFiles(item, path + "/" + item.name, index);
                })
              ).then(resolve);
            });
          }
        });
      }

      let pUpload = Promise.all(
        Array.from(items, (item, i) => {
          let entry = item.webkitGetAsEntry();
          list.push({
            type: entry.isDirectory ? "folder" : "file",
            list: [],
          });
          return scanFiles(entry, entry.name, i);
        })
      );
      pUpload.then(() => {
        this.handleGoUpload(list);
      });
    },
      dropEnd() {
          this.dragoverIndex = -1;
      },
    handleGoUpload(list) {
      this.dragoverIndex = -1;
      let obj = this.filesList.find((item) => item.active);
      if (obj) {
        // 拖到文件/缺省框上传 则上传至当前文件路径下  /文件夹 folderid
        let id =
          obj.is_file == "1"
            ? this.routerList[this.routerList.length - 1].id
            : obj.id;
        let name =
          obj.is_file == "1"
            ? this.routerList[this.routerList.length - 1].name
            : obj.name;
        this.$store.commit("setUploadFloder", { id, name });
        this.goUpload(list);
      }
      if (this.uploaderPosition == "-1") {
        let id = this.routerList[this.routerList.length - 1].id;
        let name = this.routerList[this.routerList.length - 1].name;
        this.$store.commit("setUploadFloder", { id, name });
        this.goUpload(list);
      }
      this.uploaderPosition = "";
      let el = document.getElementById("upload-0");
      el?.classList.remove("active-1");
    },
    onFileDragover(e, item, index) {
      this.uploaderPosition = item;
      if (item == "-1") {
        let el = document.getElementById("upload-0");
        el?.classList.add("active-1");
      } else {
        if (e.pageY - this.virtualDom.offsetTop <= this.virtualDom.itemSize) {
          this.virtualDom.inFirst = 1;
        } else {
          this.virtualDom.inFirst = 0;
        }

        this.dragoverIndex = index;
        item.active = true;
      }
    },
    onFileDragleave(e, item) {
      this.uploaderPosition = "";
      if (item == "-1") {
        let el = document.getElementById("upload-0");
        el?.classList.remove("active-1");
      } else {
        item.active = false;
      }
    },
    listDragleave(e) {
      if (
        e.target.id === "qVirtualDom" ||
        (e.x === 0 && e.y === 0) ||
        e.offsetX <= 0
      ) {
        this.dragoverIndex = -1;
      }
    },
    setDragIndex() {
      this.dragoverIndex = -1;
    },
    //查询文件夹名称
    findFolderInfo(id, fileList) {
      findFolderInfo({ folder_id: id }).then((res) => {
        if (res.status == "1") {
          this.$store.commit("setUploadFloder", {
            id: id,
            name: res.data.name,
          });
          this.goUpload(fileList);
        }
      });
    },
    //去上传
    goUpload(fileList) {
      this.$store.commit("dragUpload", {
        name: "query",
        value: fileList,
      });
      this.$store.commit("setAttr", {
        name: "showUpDownBox",
        val: 1,
      });
      this.$store.commit("setAttr", {
        name: "navAct",
        val: 0,
      });
    },
    //新建文件夹
    newFloder: _.debounce(
      function () {
        const currentDate = new Date();
        const year = currentDate.getFullYear().toString().slice(-2);
        const year1 = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1;
        const day = currentDate.getDate();
        const hour = currentDate.getHours().toString().padStart(2, "0");
        const minute = currentDate.getMinutes().toString().padStart(2, "0");
        const second = currentDate.getSeconds().toString().padStart(2, "0");
        const formattedMonth = month < 10 ? `0${month}` : month.toString();
        const formattedDay = day < 10 ? `0${day}` : day.toString();
        let ymd = `${year}${formattedMonth}${formattedDay}`;
        const randomInt = Math.floor(Math.random() * (999 - 100 + 1)) + 100;
        let name = `新建文件夹-${ymd}-${hour}${minute}${second}${randomInt}`;
        let params = {
          name: name,
          pid: this.params.folder_id,
          id: "",
        };
        modiFolder(params)
          .then(async (res) => {
            if (res.status == "1") {
              this.scrollToTop();
              let arr = {
                ext_icon: `${this.$glb.cdnPic}/app2023/fm_file/5.png`,
                fileCount: "0项",
                id: res.data.folder_id,
                intime: `${year1}-${formattedMonth}-${formattedDay} ${hour}:${minute}`,
                is_file: "0",
                is_locked: "0",
                name: name,
                isShowEdit: true,
              };
              this.rename = name;
              if (this.filesList.length > 0) {
                this.$set(this.filesList[0], "isShowEdit", false);
              }
              this.total += 1;
              this.allFileTotal += 1;
              this.checkedFiles = [];
              this.filesList.unshift(arr);
              this.$nextTick(() => {
                let _input = this.$refs.virtualBox.$el.querySelector(
                  "#upload-" + res.data.folder_id + " input"
                );
                if (_input) {
                  _input.select();
                }
              });
            } else if (res.status == "60010") {
              this.$toast(res.msg, 2);
            } else {
              this.$toast(res.msg, 2);
            }
          })
          .catch((err) => {});
      },
      150,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    //编辑
    editName: _.debounce(
      function (item) {
        let title = item.is_file == "0" ? "文件夹" : "文件";
        let reg = /^(?=.*\S).+$/;
        if (this.rename == "") {
          this.$toast(title + "名称不能为空，长度50字以内", 2);
          return;
        }
        if (this.rename.length > 50) {
          this.$toast(title + "名称不能为空，长度50字以内", 2);
          return;
        }
        if (!reg.test(this.rename)) {
          this.$toast(title + "名称不能为空，长度50字以内", 2);
          return;
        }
        if (item.name == this.rename) {
          this.$set(item, "isShowEdit", false);
          return;
        }
        if (item.isShowEdit == false) {
          return;
        }
        if (item.is_file == "0") {
          modiFolder({
            name: this.rename,
            pid: this.params.folder_id,
            id: item.id,
          })
            .then((res) => {
              if (res.status == "1") {
                this.$set(item, "isShowEdit", false);
                this.$set(item, "name", this.rename);
                this.$toast(res.msg, 1);
                this.getFiles(1);
              } else {
                this.$set(item, "isShowEdit", false);
                this.$toast(res.msg, 2);
              }
            })
            .catch((err) => {});
        } else {
          fileReName({ id: item.id, name: this.rename }).then((res) => {
            if (res.status == "1") {
              this.$toast(res.msg, 1);
              this.getFiles(1);
            } else {
              this.$set(item, "isShowEdit", false);
              this.$toast(res.msg, 2);
            }
          });
        }
      },
      600,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    //选中重命名
    selectRename() {
      let fileIndex = this.checkedFiles[0];
      if(this.oldIndex >=0 ){  
        this.$set(this.filesList[this.oldIndex], "isShowEdit", false);
      }
      this.oldIndex = fileIndex;
      if (fileIndex >= 0) {
        this.$nextTick(() => {
          this.$set(this.filesList[fileIndex], "isShowEdit", true);
          setTimeout(() => {
            let _input = this.$refs.virtualBox.$el.querySelector(
              "#upload-" + this.filesList[fileIndex].id + " input"
            );
            if (_input) {
              _input.select();
            }
          }, 50);
        });
      }
      this.rename = this.filesList[fileIndex].name;
    },
    visibleChange(e) {
      if (e) {
        this.dropdown = ["contextmenu", "click"];
        this.isOpen = e;
        this.isOpen2 = true;
      } else {
        this.isOpen2 = false;
        this.dropdown = ["contextmenu"];
      }
    },
    goAskdetail(type) {
      if (type == 1) {
        window.open("/askdetail/84");
      } else if (type == 2) {
        window.open("/askdetail/82");
      } else {
        window.open("/askdetail/85");
      }
    },
    onError(e, item) {
      this.$set(item, "showPlay", false);
    },
    riskveAgain(accessid) {
      if (this.showRisk == 1) {
        this.goDownload('',this.downItem, accessid);
      }
    },
  },
};
</script>

<style lang="scss">
@import "./index.scss";
</style>
