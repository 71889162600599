<template>
  <div class="PayVipPopup-box" v-if="isShow">
    <div class="content">
      <div class="button-btn">
        <svg-btn
          @click="handleClose"
          style="color: #ffffff;width: 16px;height: 16px;cursor: pointer;"
          icon-class="icon-model-close"
        ></svg-btn>
      </div>
      <PayOrderVip
        :isPopup="true"
        @allQuan="PayJurisisShow = true"
        @SuccessClose="handleClose"
      ></PayOrderVip>
      <PayJuris
        :isShow="PayJurisisShow"
        @handleClose="PayJurisisShow = false"
      ></PayJuris>
    </div>
  </div>
</template>
  
<script>
import PayOrderVip from "../PayOrderVip/index.vue";
import PayJuris from "../PayJuris/index";
export default {
  components: {
    PayOrderVip,
    PayJuris,
  },
  name: "title",
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  watch: {},
  data() {
    return {
      PayJurisisShow: false,
    };
  },
  mounted() {},
  methods: {
    handleClose() {
      this.$emit("handleClose");
    },
  },
};
</script>
  
<style lang="scss" scoped>
.PayVipPopup-box {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 100;

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .button-btn {
      position: absolute;
      right: 16px;
      top: 16px;
    }
  }
}
</style>
  