<template>
  <el-dialog
    title=""
    :visible.sync="cashShow"
    width="360px"
    :before-close="handleClose"
    :append-to-body="true"
    class="cashDialog"
    :close-on-click-modal="false"
  >
    <div
      class="content"
      :style="{
        backgroundImage: 'url(' + cashImg + ')',
        height: cashType == 2? '260px' : '340px'
      }"
    >
      <fm-button class="cashButton" @click="confirm" round size="medium">{{
        buttonText
      }}</fm-button>
      <svg-btn
        @click="handleClose"
        :class="cashType == 2?'close-icon':'close-icon2'"
        icon-class="icon-cash-close"
      />
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    cashShow: {
      type: Boolean,
      default: false,
    },
    cashType: {
      type: String || Number,
      default: 2,
    },
    cashImg: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '立即收下',
    },
  },
  data() {
    return {}
  },
  created() {},
  methods: {
    confirm(){
      this.$emit('confirm')
    },
    handleClose() {
      this.$emit('handleClose')
    },
  },
}
</script>

<style lang="scss" scoped>
.cashDialog {
  ::v-deep .el-dialog {
    box-shadow: none !important;
    background: transparent !important;
  }
  ::v-deep .el-dialog__body {
    padding: 0 !important;
  }
  .content {
    position: relative;
    background-repeat: no-repeat;
    width: 360px;
    background-size: contain;
    background-position: center;
    .cashButton {
      background: linear-gradient(137deg, #fd513a 0%, #fe4162 100%);
      position: absolute;
      left: 50%;
      bottom: -97px;
      transform: translate(-50%, -50%);
      width: 168px;
      height: 48px;
      border-radius: 24px;
      font-weight: 600;
      font-size: 18px;
      color: #ffffff;
      line-height: 28px;
    }
    .close-icon {
      width: 28px;
      height: 28px;
      position: absolute;
      right: -28px;
      top: -28px;
      cursor: pointer;
    }
    .close-icon2 {
      width: 28px;
      height: 28px;
      position: absolute;
      right: -18px;
      top: -6px;
      cursor: pointer;
    }
  }
}
</style>
