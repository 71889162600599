<template>
  <div class="carousel-container">
    <transition-group name="myfade" mode="out-in">
      <!-- 使用 v-for 渲染多个数据 -->
      <div v-for="item in items" :key="item.id" class="carousel-item">
        <img class="logo" :src="item.logo" />
        <div class="right">
          <div class="name">{{ item.nickname }}</div>
          <div class="oneTime">刚刚</div>
          <div class="content1">{{ item.content }}</div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { debounce } from "lodash";
export default {
  props: {
    superSlideData: Array,
    payDialog: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      items: [
        // 可以添加更多的数据
      ],
      timeStart: "",
    };
  },
  created() {
    this.getBuyList();
  },
  watch: {
    payDialog: function (newVal) {
      if (newVal) {
        this.rotateCarousel();
        this.timeStart = setInterval(this.rotateCarousel, 2600);
      } else {
        clearInterval(this.timeStart); //清除定时器
      }
    },
  },

  methods: {
    // 定时切换数据的方法
    rotateCarousel: debounce(function () {
      // 将最上面的数据放到数组末尾，实现走马灯效果
      const firstItem = this.items.shift();
      firstItem.id = Math.random(0, 1);
      this.$nextTick(() => {
        this.items.push(firstItem);
      });
    }, 300),
    getBuyList() {
      let listArr = this.superSlideData.map((item) => {
        return { ...item, id: Math.random(0, 1) };
      });
      this.items = listArr;
    },
  },
  mounted() {
    // 每隔一段时间切换数据，实现走马灯效果
    this.rotateCarousel();
    this.timeStart = setInterval(this.rotateCarousel, 2600);
  },
  beforeDestroy(){
    clearInterval(this.timeStart); //清除定时器
  },
};
</script>

<style lang="scss" scoped>
.carousel-container {
  width: 240px;
  height: 120px;
  box-sizing: border-box;
  padding-left: 10px;
}
.carousel-item {
  display: flex;
  height: 32px;
  background: #f6f6f6;
  @include useTheme {
    background: getVar("text-color1-f6f6f6");
  }
  border-radius: 16px;
  align-items: center;
  margin-bottom: 10px;
  padding: 6px 8px 6px 6px;
  width: auto;
  box-sizing: border-box;
  overflow: hidden;
  transition: all 0.55s linear;
  width: fit-content;
  max-width: 240px;

  .logo {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 5px;
    flex-shrink: 0;
    object-fit: cover;
  }
  .right {
    display: flex;
    font-size: 12px;
    font-weight: 400;
    color: #8a8b92;
    line-height: 20px;
    max-width: 201px;
    overflow: hidden;
    white-space: nowrap;
    .oneTime {
      color: #f67942;
      margin: 0 5px;
      flex-shrink: 0;
    }
  }
}
/* 进场的瞬间与离场的效果添加 */
.myfade-enter,
.myfade-leave-to {
  opacity: 0;
  transform: translateY(-42px);
}
.myfade-leave-active {
  position: absolute;
  right: 0;
}
</style>
