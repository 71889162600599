import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
// import utils from '../utils/index'
Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

const store = new Vuex.Store({
  state: {
    name: "feiMoo",
    theme: "light", //主题
    themeBtn: "light", //主题按钮选中
    globalLoading: false, // 全局loading状态，默认为false
    initialSlide: 0, //左侧图片展示和预览的联动的图片index
    screenWidth: null, //屏幕宽度
    showUpDownBox: 0, //右下角的上传下载解压框的是否开启 0 关闭 ，1 大框，2 小框
    userInfo:{},//用户信息
    memberBenefitsImg: [], // 会员权益图片
    appRightWidth:null,//右侧窗口的宽度
    equipmentFlag:0,//接口状态码控制设备管理的展示
    fmPath:'drive',
    navAct:0,
    UploadFloder: {//上传目标文件夹
      id: 0,
      name: "全部文件"
    },
    today_upload_num: "", //飞猫盘用户今日剩余上传次数
    // 下载
    downloadFile:{},
    regex:'',//黑名单
    showFlag:0,
    check_str:'',
    userMsgNub: 0,
    dragUpload:function(){},
    getDownloadFile:function(){},
    getfilescallback:function(){},
    onStartRecord:function(){},
    onEndRecord:function(){},
    closePreviewPubliccallback:function(){},
    endSelectFuncallback:function(){},
    bindPhoneCallback: null,
    handleOpenPhone: null,
    handleDropEnd: null,
    polling:'-1',//解压  //1 轮询0 停止轮询【PC端使用】
    shutdown:false,//手动关闭缩小队列弹框
    events:[],
    route_ids: ['A1'],
    voucher: '',
    is_pc: 0,
    statusNum: '',// 下载A页获取文件接口的状态码
    rebate_model: 0,
    language:'cn',
  },
  getters: {},
  mutations: {
    setAttr(state, data) {
      state[data.name] = data.val;
    },
    // 刷新getuser
    getuserVuex(state) {
      state.getusercallback();
    },
    clearCookieVuex(state) {
      state.clearCookieCallback();
    },
    // //清除vuex
    clearUserData(state) {
      (state.name = "feiMoo"),
        (state.globalLoading = false), // 全局loading状态，默认为false
        (state.initialSlide = 0), //左侧图片展示和预览的联动的图片index
        (state.screenWidth = null), //屏幕宽度
        (state.showUpDownBox = 0), //右下角的上传下载解压框的是否开启 0 关闭 ，1 大框，2 小框
        (state.userInfo = {}), //用户信息
        (state.appRightWidth = null), //右侧窗口的宽度
        (state.equipmentFlag = 0), //接口状态码控制设备管理的展示
        (state.fmPath = "drive"),
        (state.navAct = 0),
        (state.showFlag = 0),
        (state.check_str = ''),
        (state.polling='-1'),
        (state.shutdown=false),
        (state.UploadFloder = {
          //上传目标文件夹
          id: 0,
          name: "全部文件",
        }),
        (state.today_upload_num = ""), //飞猫盘用户今日剩余上传次数
        // 下载
        (state.downloadFile = {});
        (state.regex = '')
    },
    // 选中本地文件到上传列表 飞猫盘
    selectfilecallback(state){
      state.selectfilecallback();
    },
    //在文件夹里面设置选中的文件
    setUploadFloder(state, obj) {
      state.UploadFloder = obj;
    },
    // 刷新文件列表
    getfiles(state,opt) {
      state?.getfilescallback(opt);
    },
    onStartRecord(state,opt) {
      state?.onStartRecord(opt);
    },

    onEndRecord(state,opt) {
      state?.onEndRecord(opt);
    },
    getDownloadFile(state,opt){
      state.getDownloadFile(opt);
    },
    // 查询解压进度
    getProgressTaskcallback(state){
      state.getProgressTaskcallback();
    },
    closePreviewPublicVuex(state){
      state.closePreviewPubliccallback()
    },
    endSelectFunVuex(state){
      state.endSelectFuncallback()
    },
    //列表拖拽上传
    dragUpload(state,opt){
      state.dragUpload(opt);
    }
  },
  actions: {},
  modules: {},
  plugins: [vuexLocal.plugin],
});

export default store;
