import VueI18n from "../assets/vue-i18n";
import Vue from "vue";
import store from "@/store/index.js";

// Vue.use(VueI18n);

//  引入需要的语言包
import en from "./en-us.js";
import cn from "./zh-cn.js";
import hk from "./zh-hk.js";
const fullUrl = window.location.href;
if (fullUrl.includes('/en-US')) {
    store.commit("setAttr", {
        name: "language",
        val: 'en',
    })
} else if(fullUrl.includes('/zh-HK')) {
    store.commit("setAttr", {
        name: "language",
        val: 'hk',
    })
} else {
    // if (store.state.language == 'en' || store.state.language == 'cn') {
    
    // } else {
    //     store.commit("setAttr", {
    //         name: "language",
    //         val: 'cn',
    //     })
    // }
    store.commit("setAttr", {
        name: "language",
        val: 'cn',
    })
}
// 本地语言环境获取
const lang = store.state.language || "cn";
console.log(lang);

const i18n = new VueI18n({
    locale: lang, // 语言标识
    fallbackLocale: "cn", // 失败时默认语言
    silentTranslationWarn: true, // 设置为true 会屏蔽翻译在控制台报出的警告
    messages: {
        en: en,
        "cn": cn,
        'hk': hk,
    },
});
const localeLib = {
    i18n(callback) {
        this.translate = callback;
    },
    translate(key, value) {
        return key;
    }
}
// 设置语言
localeLib.i18n((key, value) => i18n.t(key, value));

export default i18n;

