<template>
  <el-dialog
      title="提示"
      :visible.sync="isShow"
      width="480px"
      :before-close="handleClose"
      custom-class="bindPhoneDialog" :append-to-body="true"  :close-on-click-modal="false">
      <div class="dialog-body">
          <div class="d-header">
              <div class="left">
                  <img :src="$utils.getPng('web4/icon-circle-fill')"/>
                  <span>{{ type==2 ? '实名认证' : '绑定手机号' }}</span>
              </div>
              <fm-button type="info" size="mini" text icon="icon-model-close" @click="handleClose"></fm-button>
          </div>
          <div class="d-content">
            <span class="tips" v-if="type!=3">
                {{ type==1 ?'请绑定手机号后再继续操作' :'请完成实名认证后再继续操作' }}
            </span>
            <span class="tips2" v-else>请输入发送至 <font class="phone">{{pcode}} {{phone}}</font> 的 <font class="num">6</font> 位验证码有效期3分钟</span>
            <div class="dc1" v-if="type==1">
                <el-select
                    class="code-select"
                    placeholder=""
                    v-model="pcode"
                    @change="phone=''"
                    :popper-append-to-body="false"
                >
                    <el-option-group label="选择国家或地区">
                    <el-option
                        :label="item.tel"
                        :value="item.tel"
                        v-for="item in cty"
                        :key="item.tel_id"
                        style="
                        min-width: 368px;
                        display: flex;
                        justify-content: space-between;
                        "
                    >
                        {{ item.name }}
                        <span>{{ item.tel }}</span>
                    </el-option>
                    </el-option-group>
                </el-select>
                <el-input class="phone-input" v-model="phone" placeholder="请输入手机号" clearable :maxlength="pcode==='+86'?11:null">
                <!-- <span class="code" slot="prepend">+86</span> -->
                
                </el-input>
            </div>
            <div class="dc2" v-else-if="type==2">
                <el-input v-model="name" placeholder="请输入真实姓名" clearable>
                </el-input>
                <el-input v-model="account" placeholder="请输入18位身份证号" clearable></el-input>
            </div>
            <div class="dc3" v-else-if="type==3">
                <div class="verifcode-box">
                    <VerificationCode ref="verificationCode"
                        :error="reload"
                        :i-loading="loading"
                        @next="next"
                        @changeload="changeload"></VerificationCode>
                </div>
                <span class="countdown"  v-if="time == 60" @click="reGetCode()">
                    重新获取验证码
                </span>
                <div class="countdown" v-else>{{ time }}s后重新获取</div>
            </div>
          </div>
          
            <div class="d-footer">
              <fm-button @click="getCode()" type="primary" size="small" v-show="type==1" :disabled="(pcode==='+86'&&phone.length<11)||!phone">下一步</fm-button>
              <fm-button @click="verifFlag ? '' : binding()" type="primary" size="small" v-show="type==3" :disabled="verifFlag">确 定</fm-button>
              <fm-button @click="name=='' || account=='' ? '' : confirm()" type="primary" size="small" v-show="type==2" :disabled="name=='' || account=='' ">确 定</fm-button>
            </div>
      </div>
    </el-dialog>
</template>

<script>
import VerificationCode from '../Dialog/VerificationCode.vue'
import { getPhonePcodeAPI } from "@/utils/apiList/account";
export default {
    components:{
        VerificationCode
    },
    props:{
        isShow: {
            type: Boolean,
            default: false
        },
        type:{
            type: Number,
            default: 1
        },
        time:{
            type: Number,
            default: 60
        }
    },
    data(){
        return{
            phone:'',
            pcode:'+86',
            loading:'',
            reload:'',
            verifCode:'',//验证码
            verifFlag:true,
            name:'',
            account:'',
            cty: []
        }
    },
    watch:{
        isShow(newVal){
            if(newVal == true){
                  if(!document.getElementById('aliyunCaptcha-window-popup')){
                    this.$AliCode.initAliCode();
                  }
            }   
        }
    },
    mounted() {
        getPhonePcodeAPI({type: 4}).then(res => {
            this.cty = res.data.list
        })
    },
    methods:{
        handleClose(){
            this.phone=''
            this.name='',
            this.account=''
            this.$emit('handleClose')
        },
        //获取验证码
        getCode(){
            if((this.pcode==='+86'&&this.phone<11)||!this.phone) return
            this.$emit('getPhoneCode',this.phone,this.pcode)
        },
        //获取验证码之后的验证码
        next(val) {
            const str = val && val.toString().replace(/,/g, "");
            this.verifCode = str;
            this.nextApi(str);
        },
        nextApi(str) {
            let params={
                pcode: this.pcode,
                phone: this.phone,
                ucode: str
            }
            this.$emit('bindPhone',params)
        },
        changeload(val){
            // this.verifCode = val;
            this.verifFlag = val
        },
        binding() {
            if (this.verifCode.length < 6) {
                return;
            }
            this.nextApi(this.verifCode);
        },
        reGetCode(){
            this.$refs.verificationCode.reset(),
            this.getCode()
        },
        confirm(){
            let data={
                name:this.name,
                account:this.account
            }
            this.$emit('confirmNameAuth',data)
        },
        onFocus(){
            this.$refs.verificationCode.resetCaret();
        }
    }
}
</script>
  
<style>
.code-select .el-select-dropdown {
    border-radius: 8px;
}
</style>