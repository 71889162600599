<template>
	<div class="bg_gray main">
		<tipHeader :phonedata="phonedata" :iscarry="false" :pageData="pageData">
		</tipHeader>
    <div class="tipsContent" v-show="tips.show">{{tips.msg}}</div>
		<div class="userbox">
			<div class="user-portrait">
				<img v-if="logo" :src="logo" mode="aspectFill"/>
        <img v-else src="../../../assets/img/defaultAvatar.png" mode="aspectFill"/>
			</div>
			<div class="user" :style="{marginTop:!uname?'-13px':0}">
				<div class="user-name overellipsis-1">{{uname}}</div>
				<div class="user-tagbox">
					<div class="user-tag">用户自行上传分享，并不代表本站立场</div>
					<div class="bule-btn_text" @click="goReport" v-if="file_status!='need_pucode'">举报</div>
				</div>
			</div>
		</div>
		<!-- 公开文件类型 -->
		<div class="filebox file1_box" v-if="status==1">
			<div class="file-top">
				<div class="file-sum">全部文件（1）</div>
				<div class="file-right">
					<div class="choose">
						全选
					</div>
					<div class="icon-box" @click="isChoosed = !isChoosed">
						<img v-if="!isChoosed" class="icon-check" src="../../../assets/img/icon_check0.png" />
						<img v-if="isChoosed"  class="icon-check" src="../../../assets/img/icon_check1.png" />
					</div>
				</div>
			</div>
			<div class="file-item">
				<div class="file-image">
					<img :src="ext_icon" mode="widthFix" height="40" width="40" />
				</div>
				<div class="file-info">
					<div class="file-name overellipsis-2">{{file_name}}.{{file_extension}}</div>
					<div class="file-size overellipsis-1">
						<span>{{file_time}}</span>
						<span style="margin-left: 8px;">{{file_size}}</span>
					</div>
					<div class="icon-box icon-box1" @click="isChoosed = !isChoosed">
						<img v-if="!isChoosed" class="icon-check" src="../../../assets/img/icon_check0.png" />
						<img v-if="isChoosed"  class="icon-check" src="../../../assets/img/icon_check1.png" />
					</div>
				</div>
			</div>
		</div>
		<!-- 已删除文件和锁定文件 -->
		<div class="file_box file1_box"
			v-if="status!=1&&(file_status=='is_del'||file_status=='is_locked'||file_status=='is_notexist')">
			<img :src="$utils.getPng('icon_sigh')" height="60" width="60"/>
			<div class="file_content" style="margin-top: 16px;margin-bottom: 2px;">此文件已被系统审核过滤或被上传者删除</div>
			<div class="file_content">无法访问</div>
		</div>
		<!-- 私有文件 -->
		<div class="file_box file1_box" v-if="status!=1&&file_status=='is_private'">
			<img :src="$utils.getPng('icon_sigh')" height="60" width="60"/>
			<div class="file_content" style="margin-top: 16px;">此链接已被上传者取消分享无法访问</div>
		</div>
		<!-- 取件码提取文件 -->
		<div class="file_box file1_box" style="padding-top: 70px;" v-if="status!=1&&file_status=='need_pucode'">
			<input class="pickUpCode"  v-model="pucode" type="text" placeholder="请输入取件码" />
			<el-button class="pickUpFile" type="primary" @click="pickUpFile()" :disabled="isDisabled">取件</el-button>
		</div>
		<!-- 提示-e-->
		<div class='delete' v-if="status!=1&&(file_status=='is_del'||file_status=='is_locked'||file_status=='is_private')">
			<div class="tips" @click="gotoArchives()">《飞猫盘违规内容处置机制》</div>
		</div>
		<!-- 去APP查看s-->
		<div class="bottom-button1" v-if="bottomButtonShow">
			<div class="notPrivate" v-if="file_status!='need_pucode'" @click="copyPassword()" ref="notPrivate">
				<img src="../../../assets/img/icon_copy.png" style="width: 20px;height: 20px;"/>
				<div class="copyPassword">复制口令</div>
			</div>
			<el-button class="blue_btn_middle" type="primary" @click="checkApp()" round v-show="showBtn">去APP查看</el-button>
		</div>
    <div style="display: inline-block;position: absolute;z-index: 777;bottom: 0;left:0;right:0;" :style="{opacity:status == 1 && switchs?.down_bottom_ad_switch == 1 ? 1 : 0}">
      <div id="banner_2_0"></div>
    </div>
	</div>
</template>

<script>
import common from '../common.js'
import tipHeader from './tipHeader.vue'
import {
  h5FileShareDetail
} from "@/utils/apiList/downpage";

export default {
  components: {
    tipHeader,
  },
  data() {
    return {
      code: "",
      status: "", //状态码
      file_status: "", //文件状态
      file_name: "", //文件名
      fshort: "",
      userid: "", //用户id
      file_size: "", //文件大小（格式化）
      file_extension: "", //文件后缀
      uname: "", //用户名
      logo: "", //用户头像地址
      phonedata: {},
      pageData: {
        type: "1",
        id: "", //文件id
      },
      tipShow: {
        val: false
      },
      icon: "", //文件图标
      tips: {
        show: false,
        msg: ''
      },
      pucode: '', //提取码
      isDisabled: false, //是否禁用
      notPrivate: '', //获取整个dom
      linkUrl: '', //整个链接地址
      showBtn: true, //控制底部按钮显示与否
      clienHeight: 0, //窗口高度
      shareUrl: '', //复制链接地址
      isPageShow: false,
      bottomButtonShow: false,
      file_time:'',
      timer: null,
      params: {},
      isChoosed:true,
      switchs: {}, // 广告开关
      adstitialType: false
    }
  },
  created() {
    this.TencentGDT()
    this.params = {code: this.$route.params.id}
    this.code = this.params.code
    this.clienHeight = document.documentElement.clientHeight
    this.phonedata = common.getPhone()
    this.getDetail()
    if (this.$route.query.pucode) {
      this.pucode = this.$route.query.pucode
    };
  },
  destroyed() {
    document.body.classList.remove('mobile')
  },
  mounted() {
    document.body.classList.add('mobile')
    //判断窗口高度是否改变
    window.onresize = () => {
      if (this.timer) return
      this.timer = setTimeout(() => {
        let phoneData = common.getPhone()
        
        this.showBtn = (phoneData.is_ios || phoneData.is_android)
        this.timer = null
      }, 300)
    };
  },
  methods: {
    TencentGDT(){
      const that = this
      window.adstitial = '';
      window.adbanner = '';
      // 腾讯广告全局申明只需运行一次
      window.TencentGDT = window.TencentGDT || [];

      // 底部横幅广告
      TencentGDT.push({
        app_id: '1207601023',
        placement_id: '1112116651732617',
        type: 'native',
        display_type: 'banner',
        carousel: 3000,
        containerid: 'banner_2_0',
        onComplete: function(res) {
          if (res.ret == 0) {
            console.log('banner成功');
          } else {
            console.log('banner失败', res);
          }
        }
      });

    // 插屏广告初始化
    TencentGDT.push({
      app_id: '1207601023',
      placement_id: '4172810601432232',
      type: 'native',
      display_type: 'interstitial',
      count: 1,
      onComplete: function(res) {
        if (res && res.ret === 0) {
          window.adstitial = res.data[0];
          that.adstitialType = true
          if(that.adstitialType && that.switchs?.down_vip_popup_ad_switch == 1){
            that.adstitialType = false
            window['TencentGDT'].NATIVE.renderAd(window['adstitial']);
          }
        } else {
          console.log('interstitial',res);
          setTimeout(function() {
            window.TencentGDT.NATIVE.loadAd('4172810601432232');
          }, 3000);
        }
      }
    });

    // H5 SDK接入
    (function () {
      var doc = document,
        h = doc.getElementsByTagName('head')[0],
        s = doc.createElement('script');
      s.async = true;
      s.src = '//qzs.qq.com/qzone/biz/res/i.js';
      h && h.insertBefore(s, h.firstChild);
    })();
    },
    copyText(txt) {
      txt += "";
      if (txt == "null" || txt == "undefined" || txt == "") {
        return;
      }        
      if (document.queryCommandSupported('copy')) {
        let textarea = document.createElement("textarea")
        textarea.value = txt
        textarea.readOnly = "readOnly"
        document.body.appendChild(textarea)
        textarea.select() // 选中文本内容
        textarea.setSelectionRange(0, txt.length)
        document.execCommand("copy")
        textarea.remove()
        return true
      } else {
        return false
      }
    },
    GetQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    goReport() {
      //举报
      window.location.href = common.reportUrl
    },
    getDetail() {      
      h5FileShareDetail({code: this.code}).then(res => {        
        localStorage.setItem("shareUrl", res.data.shareUrl)
        if (res.status == 1) {
          this.shareUrl = res.data.shareUrl
          this.switchs = res.data.switchs || {}
          if(this.switchs?.down_vip_popup_ad_switch == 1 && this.adstitialType){
            setTimeout(()=>{
              this.adstitialType = false
              window['TencentGDT'].NATIVE.renderAd(window['adstitial']);
            },500)
          }
          
          this.resourceData = res.data.fileLists
          this.bottomButtonShow = true
        } else {
          this.resourceData = res.data
          this.file_status = res.data.file_status //文件状态
          this.bottomButtonShow = this.file_status != 'is_del' && this.file_status != 'is_locked' &&
          this.file_status != 'is_notexist' && this.file_status != 'is_private' && this
            .file_status != 'need_pucode' ? true : false
        }
        this.status = res.status
        this.processingData()
      })
    },
    processingData() {
      //渲染数据
      if (this.status == 1) {
        //文件公开类型
        let olddata = this.resourceData[0];
        let iconName = common.fileIcon(olddata.file_extension)
        this.ext_icon = olddata.ext_icon
        this.pageData.id = olddata.openCode //文件id
        this.fshort = olddata.fshort
        this.userid = olddata.userid //用户id
        this.file_size = olddata.file_size //文件大小（格式化）
        this.file_extension = olddata.file_extension //文件后缀
        this.file_time = olddata.file_time
        this.uname = olddata.uname //用户名
        this.logo = olddata.logo //用户头像地址
        // this.file_name = this.cutString(olddata.file_name, 62) //文件名
        this.file_name = olddata.file_name
        this.icon = "../../static/icon/" + iconName + ".png"
      } else {
        let olddata = this.resourceData
        this.uname = olddata.uname //用户名
        this.logo = olddata.logo
        this.pageData.id = olddata.openCode
      }
    },
    cutString(str, len) {
      if (str.length * 2 <= len) { //length属性读出来的汉字长度为1
        return str;
      }
      var strlen = 0;
      var s = "";
      for (var i = 0; i < str.length; i++) {
        s = s + str.charAt(i);
        if (str.charCodeAt(i) > 128) {
          strlen = strlen + 2;
          if (strlen >= len) {
            return s.substring(0, s.length - 1) + "...";
          }
        } else {
          strlen = strlen + 1;
          if (strlen >= len) {
            return s.substring(0, s.length - 2) + "...";
          }
        }
      }
      return s;
    },
    copyPassword() {
      //复制口令
      const share_url = this.shareUrl || localStorage.getItem("shareUrl")
      if (this.copyText(share_url)) {
        this.tips.show = true
        this.tips.msg = '复制口令链接成功，可在飞猫盘APP查看'
        this.$refs.notPrivate.classList.add('forbidStyle');
        setTimeout(() => {
          this.tips.show = false;
          this.$refs.notPrivate.classList.remove('forbidStyle');
        }, 3000);
      } else {
        this.tips.show = true
        this.tips.msg = '您的浏览器不支持复制'
        setTimeout(() => this.tips.show = false, 2000)
      }
    },
    pickUpFile() {
      //取件码提取文件
      if (!this.pucode) {
        this.tips.show = true
        this.tips.msg = '取件码不能为空'
        this.isDisabled = true
        setTimeout(() => {
          this.tips.show = false
          this.isDisabled = false
        }, 1000)
        return
      }
      h5FileShareDetail({code: this.code, pucode: this.pucode}).then(res => {
        this.status = res.status
        this.shareUrl = res.data.shareUrl
        localStorage.setItem("shareUrl", this.shareUrl)
        if (res.status == 1) {
          this.params.pucode = this.pucode
          this.resourceData = res.data.fileLists
          this.switchs = res.data.switchs || {}
          if(this.switchs?.down_vip_popup_ad_switch == 1 && this.adstitialType){
            setTimeout(()=>{
              this.adstitialType = false
              window['TencentGDT'].NATIVE.renderAd(window['adstitial']);
            },500)
          }
          //取件码获取成功后  file_status设置为空
          this.file_status = ''
          this.bottomButtonShow = true
          this.processingData()
        } else {
          if (res.data.file_status && res.data.file_status != "is_errorpucode") {
            this.file_status = res.data.file_status
            this.bottomButtonShow = this.file_status != 'is_del' && this.file_status !=
              'is_locked' &&
              this.file_status != 'is_notexist' && this.file_status != 'is_private' && this
              .file_status != 'need_pucode' ? true :
              false
              this.processingData()
          } else {
            this.tips.show = true
            this.tips.msg = '取件码验证失败，请重试'
            setTimeout(() => {
              this.tips.show = false
            }, 1000)
          }
        }
      })
    },
    gotoArchives() {
      //固定链接去飞猫盘违规内容处置机制
      window.location.href = 'https://fmph5.jingfile.com/agreement/?code=98'
    },
    checkApp() {
      // this.pageData.type = "7"
      const timeout = this.phonedata.is_android ? 2000 : 3000
      common.checkOpen(() => {
        if (this.phonedata.is_android) {
          window.top.location.href = common.apkstore;
        } else {
          window.top.location.href = common.appstore;
        }
      }, timeout);
      if (this.phonedata.third_party_browser || this.phonedata.is_baiduboxapp) { // 通过第三方app跳转至浏览器打开
        // that.copyText(localStorage.getItem("shareUrl"));
        let _wapUrl = '/wap.html?istipShow=true&share_type=' + this.pageData.type + '&code=' + this.pageData.id + '&iscarry=' + false;
        if (this.params.pucode) {
          _wapUrl += '&pucode=' + this.params.pucode
        }
        if (this.params.code) {
          _wapUrl += '&fshort=' + this.params.code
        }
        location.href = _wapUrl
      } else { // 直接在浏览器打开
        // that.copyText(localStorage.getItem("shareUrl"));
        common.clickOpen(this.phonedata, this.pageData, this.params);
      }
    }
  }
}
</script>

<style lang='scss' scoped>
@mixin multiline($num) {
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $num;
    line-clamp: $num;
    -webkit-box-orient: vertical;
    display: block; /* 当前元素本身是inline的，因此需要设置成block模式 */
    white-space: nowrap; /* 因为设置了block，所以需要设置nowrap来确保不换行 */
    overflow: hidden; /* 超出隐藏结合width使用截取采用效果*/
    text-overflow: ellipsis; /* 本功能的主要功臣，超出部分的剪裁方式 */
    -o-text-overflow: ellipsis; /* 特定浏览器前缀 */
    text-decoration: none; /* 无用 */
  }

.forbidStyle {
  pointer-events: none;
}

.bg_gray {
  background:linear-gradient(#F6F6F6 0%,#fff 50%,#fff 50%,#fff 100%);
  height: 100vh;
}

.userbox {
  background: #F6F6F6;
  padding: 13px 20px;
  box-sizing: border-box;
  display: flex;
  height: 70px;
}

.user-portrait {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-right: 10px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  border: 1px solid #fff;
}

.user-portrait>img {
  width: 100%;
  height: 100%;
  display: block;
}

.user {
  width: 85%;
  height: 40px;
}

.user-name {
  height: 20px;
  font-size: 16px;
  font-weight: 500;
  color: #111734;
}

.user-tagbox {
  font-size: 12px;
  margin-top: 8px;
  height: 12px;
  font-weight: 400;
  color: #858A9C;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-tag {
  display: block;
}

.bule-btn_text {
  /* width: 48rpx;
  height: 24rpx;
  font-size: 24rpx;
  font-weight: 400;
  color: #F67942;
  line-height: 24rpx;
  background: transparent;
  position: absolute;
  bottom: 282rpx; */
  color: #F67942;
}

.filebox {
  min-height: 200px;
  padding: 16px;
  font-size: 14px;
  font-weight: 500;
  color: #111734;
  border-radius: 16px 16px 0px 0px;
  background-color: #fff;
}

.file_box {
  padding: 12px 20px;
  font-size: 14px;
  font-weight: 500;
  color: #111734;
  border-radius: 16px 16px 0 0;
  background-color: #fff;
  padding-bottom: 109px;
}

.file_box {
  padding-top: 104px;
  text-align: center;
  font-size: 16px;
}

.pickUpCode {
  outline: none;
  border: none;
  width: 295px;
  height: 48px;
  line-height: normal;
  text-align: center;
  background: #F1F2F6;
  font-size: 14px;
  border-radius: 24px;
  font-weight: 500;
  margin: 0 auto;
}
.pickUpCode::placeholder {
  font-size: 14px;
}

.pickUpFile {
  width: 295px;
  height: 48px;
  border-radius: 24px;
  margin: 20px auto 16px;
  font-weight: 500;
  font-size: 14px;
}
.pickUpFile.is-disabled {
  color: rgba($color: #000000, $alpha: 0.3);
  background-color: #f7f7f7 !important;
  border-color: #f7f7f7 !important;
}

.file-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 13px;
  .file-sum {}
  .file-right {
    display: flex;
    align-items: center;
    .choose {
      margin-right: 4px
    }
  }
}

.file-item {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.file_content {
  font-size:14px;
  font-weight: 400;
  color: #111734;
}

/* .file_content:first-child{
  margin-top: 20rpx;
} */



.file-image {
  margin-right: 16px;
  img {
    max-height: 40px;
    max-width: 40px;
  }
}

.file-info {
  width: 85%;
  max-height: 58px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
}

.file-name {
  align-items: center;
  word-wrap: break-word;
  word-break: break-all;
  white-space: pre-wrap;
  font-size: 14px;
  line-height: 21px;
  max-width: 240px;
}

.file-size {
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  color: #858A9C;
}


.notPrivate {
  margin-right: 16px;
  text-align: center;
  width: 96px;
}

.copyPassword {
  margin-top: 4px;
  font-size: 12px;
  color: #111734;
}

.tipsContent {
  padding: 16px 24px;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  background: #000000;
  position: fixed;
  top: 52px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  opacity: 0.7;
  white-space: nowrap;
}

.delete {
  position: absolute;
  left: 50%;
  bottom: 166px;
  transform: translateX(-50%);
  text-align: center;
  .tips {
    font-size: 12px;
    font-weight: 400;
    color: #F67942;
  }
}

::v-deep .uni-input-input {
  font-size: 14px;
}

.main {
  line-height: normal;
  letter-spacing: normal;
  width: 100vw;
  height: 100vh;
}
.bottom-button1 {
  position: fixed;
  bottom: 65px;
  width: 100vw;
  height: 109px;
  /* transition: all 0.5s ease-in-out; */
  z-index: 999;
  background-color: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  padding-top: 12px;
}
.file1_box{

  // height: calc(100vh - 140rpx - 128rpx - 390rpx);
}
.icon-box{
  height: 15px;
  img {
    display: block;
  }
}

.icon-check {
  width: 27px;
  height: 15px;
}
.icon-box1{
  position: absolute;
  right: 0;
  top:10px;
}
.blue_btn_middle {
  width: 150px;
  height: 40px;
  padding: 0;
  font-size: 16px;
  box-shadow: 0px 1px 4px 0px rgba(26, 44, 119, 0.4);
}
</style>

<style lang="scss">
.mobile #app{
  .app-box {
    min-width: 0;
  }
  .navTop {
    display: none;
  }
  .app-bottom {
    position: static;
    height: 100vh;
    transform: none;
  }
}
</style>