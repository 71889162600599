<template>
	<div class="share">
    <tipHeader class="topHeader" :phonedata="phonedata" :iscarry="false" :pageData="pageData">
    </tipHeader>
    <div class="tipsContent" v-show="tips.show">{{tips.msg}}</div>
    <div class="bg_gray main" @scroll="scroll">
      <div class="userbox">
        <div class="user-portrait">
          <img v-if="userInfo.avatar" :src="userInfo.avatar" mode="aspectFill" />
          <img v-else src="../../../assets/img/defaultAvatar.png" mode="aspectFill"/>
        </div>
        <div class="user">
          <div class="user-name overellipsis-1" v-if="userInfo.uname">{{userInfo.uname}}</div>
          <div class="user-tagbox">
            <div class="user-tag">用户自行上传分享，并不代表本站立场</div>
            <div class="bule-btn_text" @click="goReport" v-if="showStatus != 80003 && showStatus != 80004">举报</div>
          </div>
        </div>
      </div>
      <!-- 公开文件类型 -->
      <div class="filebox" :class="resourceData.length > 0 ? '':'minheight'" v-if="showStatus == 1">
        <div class="file-sum">全部文件（{{countNum}}）</div>
        <div class="" v-if="resourceData.length > 0">
          <div class="file-item" v-for="(item,index) in resourceData" :key="item.file_id">
            <div class="file-img">
              <img :src="item.ext_icon" mode="widthFix" />
            </div>
            <div class="file-info">
              <div class="file-name overellipsis-2">{{item.file_name}}.{{item.file_extension}}</div>
              <div class="file-size overellipsis-1">
                <span>{{item.file_time}}</span>
                <span style="margin-left: 8px;">{{item.file_size}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="empty" v-else>
          <img class="emptyImg" :src="$utils.getPng('icon_empty2')" alt="">
          <div class="emptyTit">
            暂无文件
          </div>
        </div>
      </div>
      <!-- 已删除文件和锁定文件 -->
      <div class="file_box file1_box" v-if="showStatus == 80005 || showStatus == 80001">
        <img :src="$utils.getPng('icon_sigh')" />
        <div class="file_content" style="margin-top: 16px;margin-bottom: 8px;">此链接已被上传者取消分享无法访问</div>
      </div>
      <div class="file_box file1_box" style="padding-top: 70px;" v-if="showStatus == 80003 || showStatus == 80004">
        <input class="pickUpCode" v-model="pucode" type="text" placeholder="请输入取件码">
        <el-button class="pickUpFile" type="primary" @click="pickUpFile()" :disabled="isDisabled">取件</el-button>
      </div>
      <!-- 提示-e-->
      <div class='delete' v-if="showStatus == 80005">
        <div class="tips" @click="gotoArchives()">《飞猫盘违规内容处置机制》</div>
      </div>
    </div>
    <div class="bottom-button1" v-if="bottomButtonShow && resourceData.length > 0">
      <div class="notPrivate" v-if="file_status!='need_pucode'" @click="copyPassword()" ref="notPrivate">
        <img src="../../../assets/img/icon_copy.png" style="width: 20;height: 20px;" />
        <div class="copyPassword">复制口令</div>
      </div>
      <el-button class="blue_btn_middle" type="primary" @click="checkApp()" round v-show="showBtn">去APP查看</el-button>
    </div>
    
    <div style="display: inline-block;position: absolute;z-index: 777;bottom: 0;left:0;right:0;" :style="{opacity:(showStatus != 80003 || showStatus != 80004) && switchs?.down_bottom_ad_switch == 1 ? 1 : 0}">
      <div id="banner_2_0"></div>
    </div>
  </div>
</template>

<script>
import tipHeader from '../../DownPage/compoents/tipHeader.vue'
import common from '../../DownPage/common.js'
import {
  h5GatherDetail
} from "@/utils/apiList/downpage";

	export default {
		components: {
			tipHeader,
		},
		data() {
			return {
				code: "",
				status: "", //状态码
				file_status: "", //文件状态
				file_name: "", //文件名
				fshort: "",
				userid: "", //用户id
				file_size: "", //文件大小（格式化）
				file_extension: "", //文件后缀
				uname: "", //用户名
				logo: "", //用户头像地址
				phonedata: {},
				pageData: {
					type: "1",
					id: "", //文件id
				},
				tipShow: {
					val: false
				},
				icon: "", //文件图标
				pucode: '', //提取码
				isDisabled: false, //是否禁用
				notPrivate: '', //获取整个dom
				linkUrl: '', //整个链接地址
				showBtn: true, //控制底部按钮显示与否
				clienHeight: 0, //窗口高度
				shareUrl: '', //复制链接地址
				isPageShow: false,
				bottomButtonShow: false,
				userInfo:{},
        tips: {
          show: false,
          msg: ''
        },
				pageNum:1,
				resourceData:[],
				gather:{},
				errorMsg:'取件码验证失败，请重试',
				showStatus:1,
        params: {},
				countNum:0,
        timer: null,
        switchs: {}, // 广告开关
        adstitialType: false
			}
		},
    created(){
      this.TencentGDT()
    },
		mounted() {
      document.body.classList.add('mobile')
			this.params = this.$route.params
			this.code = this.params.code
			this.clienHeight = document.documentElement.clientHeight
			this.phonedata = common.getPhone()
			this.getDetail()
      //初始化判断路径是否带取件码
			if (this.$route.query.pucode) {
				this.pucode = this.$route.query.pucode
			};
			window.addEventListener('pageshow', e => {
				if (e.persisted) {
					setTimeout(() => {
						location.reload()
					}, 500)
				}
			})
		},
    destroyed(){
      document.body.classList.remove('mobile')
    },
		methods: {
      TencentGDT(){
        const that = this
      window.adstitial = '';
      window.adbanner = '';
      // 腾讯广告全局申明只需运行一次
      window.TencentGDT = window.TencentGDT || [];

      // 底部横幅广告
      TencentGDT.push({
        app_id: '1207601023',
        placement_id: '1112116651732617',
        type: 'native',
        display_type: 'banner',
        carousel: 3000,
        containerid: 'banner_2_0',
        onComplete: function(res) {
          if (res.ret == 0) {
            console.log('banner成功');
          } else {
            console.log('banner失败', res);
          }
        }
      });

    // 插屏广告初始化
    TencentGDT.push({
      app_id: '1207601023',
      placement_id: '4172810601432232',
      type: 'native',
      display_type: 'interstitial',
      count: 1,
      onComplete: function(res) {
        if (res && res.ret === 0) {
          window.adstitial = res.data[0];
          that.adstitialType = true
          if(that.adstitialType && that.switchs?.down_vip_popup_ad_switch == 1){
            that.adstitialType = false
            window['TencentGDT'].NATIVE.renderAd(window['adstitial']);
          }
        } else {
          console.log('interstitial',res);
          setTimeout(function() {
            window.TencentGDT.NATIVE.loadAd('4172810601432232');
          }, 3000);
        }
      }
    });

    // H5 SDK接入
    (function () {
      var doc = document,
        h = doc.getElementsByTagName('head')[0],
        s = doc.createElement('script');
      s.async = true;
      s.src = '//qzs.qq.com/qzone/biz/res/i.js';
      h && h.insertBefore(s, h.firstChild);
    })();
    },
			copyText(txt) {
				txt += "";
				if (txt == "null" || txt == "undefined" || txt == "") {
					return;
				}        
				if (document.queryCommandSupported('copy')) {
					let textarea = document.createElement("textarea")
					textarea.value = txt
					textarea.readOnly = "readOnly"
					document.body.appendChild(textarea)
					textarea.select() // 选中文本内容
					textarea.setSelectionRange(0, txt.length)
					document.execCommand("copy")
					textarea.remove()
          return true
				} else {
          return false
				}
			},
			goReport() {
				//举报
				window.location.href = common.reportUrl
			},
			getDetail() {
        this.loading = true
        h5GatherDetail({
					gather_code: this.code,
					page:this.pageNum,
					limit:30,
					sort:0,
					fetch_code: this.pucode
				}).then(res => {
					localStorage.setItem("shareUrl", res.data.share_url)
					if (res.status == 1) {
            this.params.pucode = this.pucode
            this.shareUrl = res.data.share_url
						this.showStatus = res.status;
						this.countNum = res.data.count; //总数
						this.resourceData = this.resourceData.concat(res.data.gather_files || []);
						this.userInfo = res.data.user;
						this.gather = res.data.gather;
						this.pageData.id = res.data.gather.id;
						this.bottomButtonShow = true            
            this.loading = this.resourceData.length >= res.data.count
            
            this.switchs = res.data.switchs || {}
            if(this.switchs?.down_vip_popup_ad_switch == 1 && this.adstitialType){
              setTimeout(()=>{
                this.adstitialType = false
                window['TencentGDT'].NATIVE.renderAd(window['adstitial']);
              },500)
            }
					} else if(res.status == '80001') {
						//未找到文件
						this.showStatus = res.status;
					}else if(res.status == '80003'){
						//请输入取件码（首次进，不需要toast）
						this.showStatus = res.status;
						this.userInfo = res.data.user;
					}else if(res.status == '80004'){
						//输入取件码错误弹toast
						this.showStatus = res.status;
						this.tips.show = true;
						this.tips.msg = res.msg
						setTimeout(() => {
							this.tips.show = false;
						}, 3000);
					}else if(res.status == '80005') {
						//私有文件
						this.showStatus = res.status;
						this.userInfo = res.data.user;
					}
				})
			},
			copyPassword() {
				//复制口令
        const share_url = this.shareUrl || localStorage.getItem("shareUrl")
        if (this.copyText(share_url)) {
          this.tips.show = true
          this.tips.msg = '复制口令链接成功，可在飞猫盘APP查看'
          this.$refs.notPrivate.classList.add('forbidStyle');
          setTimeout(() => {
            this.tips.show = false;
            this.$refs.notPrivate.classList.remove('forbidStyle');
          }, 3000);
        } else {
          this.tips.show = true
          this.tips.msg = '您的浏览器不支持复制'
          setTimeout(() => this.tips.show = false, 2000)
        }
			},
			pickUpFile() {
				//取件码提取文件        
				if (!this.pucode) {
					this.tips.show = true
          this.tips.msg = '取件码不能为空'
					this.isDisabled = true
					setTimeout(() => {
						this.tips.show = false
						this.isDisabled = false
					}, 1000)
					return
				}
				this.getDetail()
			},
			gotoArchives() {
				//固定链接去飞猫盘违规内容处置机制
				window.location.href = 'https://fmph5.jingfile.com/agreement/?code=98'
			},
			gotoHelp() {
				//固定链接去飞猫盘帮助中心
				window.location.href = 'https://help.feimaoyun.com/feimaoyun'
			},
			checkApp() {
				let that = this;
				this.pageData.type = "7"
        let timeout = that.phonedata.is_android ? 2000 : 3000
				common.checkOpen(() => {
					if (that.phonedata.is_android) {
						window.location.href = common.apkstore;
					} else {
						window.location.href = common.appstore;
					}
				}, timeout);
				if (that.phonedata.third_party_browser || that.phonedata.is_baiduboxapp) { // 通过第三方app跳转至浏览器打开
					// that.copyText(localStorage.getItem("shareUrl"));
          let _wapUrl = '/wap.html?istipShow=true&share_type=' + this.pageData.type + '&code=' + this.pageData.id + '&iscarry=' + false;
          if (that.params.pucode) {
            _wapUrl += '&pucode=' + that.params.pucode
          }
          if (that.params.code) {
            _wapUrl += '&fshort=' + that.params.code
          }
          location.href = _wapUrl
				} else { // 直接在浏览器打开
					// that.copyText(localStorage.getItem("shareUrl"));
					common.clickOpen(that.phonedata, that.pageData, that.params);
				}
			},
			scroll(e){
        const {scrollTop, clientHeight, scrollHeight} = e.target
        if (scrollTop + clientHeight > scrollHeight-100 && !this.loading){
          this.pageNum += 1;
				  this.getDetail()
        }
			},
		}
	}
</script>

<style lang='scss' scoped>
@mixin multiline($num) {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $num;
  line-clamp: $num;
  -webkit-box-orient: vertical;
  display: block; /* 当前元素本身是inline的，因此需要设置成block模式 */
  white-space: nowrap; /* 因为设置了block，所以需要设置nowrap来确保不换行 */
  overflow: hidden; /* 超出隐藏结合width使用截取采用效果 */
  text-overflow: ellipsis; /* 本功能的主要功臣，超出部分的剪裁方式 */
  -o-text-overflow: ellipsis; /* 特定浏览器前缀 */
  text-decoration: none; /* 无用 */
}
*{
  box-sizing: border-box;
}
.share {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

// 适配ios safari浏览器100vh问题
@supports (-webkit-touch-callout: none) {
  .share {
    height: -webkit-fill-available;
  }
}

.forbidStyle {
  pointer-events: none;
}

.bg_gray {
  background:linear-gradient(#F6F6F6 0%,#fff 50%,#fff 50%,#fff 100%);
  /* background-color: red ; */
}

.userbox {
  background: #F6F6F6;
  padding: 13px 20px;
  box-sizing: border-box;
  display: flex;
  height: 70px;
}

.user-portrait {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-right: 10px;
  overflow: hidden;
  border: 1px solid #fff;
}

.user-portrait>img {
  width: 100%;
  height: 100%;
}

.user {
  width: 85%;
  height: 44px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.user-name {
  height: 20px;
  line-height: 18px;
  font-size: 16px;
  font-weight: 500;
  color: #111734;
}

.user-tagbox {
  font-size: 12px;
  height: 12px;
  line-height: 12px;
  font-weight: 400;
  color: #858A9C;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-tag {
  display: block;
}

.bule-btn_text {
  color: #F67942;
}

.filebox {
  padding: 16px;
  font-size: 14px;
  font-weight: 500;
  color: #111734;
  border-radius: 16px 16px 0px 0px;
  background-color: #fff;
}
.file_box {
  padding: 20px;
  font-size: 14px;
  font-weight: 500;
  color: #111734;
  border-radius: 16px 16px 0px 0px;
  background-color: #fff;
}

.file_box {
  padding-top: 104px;
  text-align: center;
  font-size: 16px;
}

.file_box>img {
  width: 60px;
  height: 60px;
}

.pickUpCode {
  outline: none;
  border: none;
  width: 295px;
  height: 48px;
  text-align: center;
  background: #F1F2F6;
  line-height: 48px;
  font-size: 14px;
  border-radius: 24px;
  font-weight: 500;
  margin: 0 auto;
}

.pickUpFile {
  width: 295px;
  height: 48px;
  border-radius: 24px;
  margin: 20px auto 16px;
  font-weight: 500;
  font-size: 14px;
}
.pickUpFile.is-disabled {
  color: rgba($color: #000000, $alpha: 0.3);
  background-color: #f7f7f7 !important;
  border-color: #f7f7f7 !important;
}

.file-sum {
  margin-bottom: 12px;
}

.file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  box-sizing: border-box;
}

.file_content {
  font-size: 14px;
  font-weight: 400;
  color: #111734;
}

/* .file_content:first-child{
  margin-top: 10px;
} */



.file-img {
  flex: none;
  margin-right: 16px;
  overflow: hidden;
}

.file-img>img {
  width: 40px;
  height: 40px;
}

.file-info {
  width: 85%;
  max-height: 58px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.file-name {
  align-items: center;
  word-wrap: break-word;
  word-break: break-all;
  white-space: pre-wrap;
  font-size: 14px;
  line-height: 21px;
  @include multiline(1);
  max-width: 270px;
}

.file-size {
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  color: #858A9C;
}

.notPrivate {
  line-height: normal;
  margin-right: 16px;
  text-align: center;
  width: 96px;
}

.copyPassword {
  margin-top: 4px;
  font-size: 12px;
  color: #111734;
}

.tipsContent {
  padding: 16px 24px;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  background: #000000;
  position: fixed;
  top: 52px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  opacity: 0.7;
  white-space: nowrap;
}

.delete {
  position: absolute;
  left: 50%;
  bottom: 141px;
  transform: translateX(-50%);
  text-align: center;

  .tips {
    font-size: 12px;
    font-weight: 400;
    color: #F67942;
  }
}

::v-deep .uni-input-input {
  font-size: 14px;
}

.main {
  flex: auto;
  letter-spacing: normal;
  overflow-y: auto;
}
.bottom-button1 {
  flex: none;
  width: 100%;
  height: 109px;
  background-color: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  padding-top: 12px;
  margin-bottom: 65px;
}
.file1_box{
  height: calc(100vh - 70px - 64px - 195px);
}
.scroll-div_H{
  box-sizing: border-box;
  /* position: fixed; */
  /* border: 2px solid rebeccapurple; */
  /* height: calc(100vh - 70px - 6px); */
}
.empty{
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 20%;
}
.emptyImg{
  width: 94px;
  height: 94px;
}
.emptyTit{
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #BDBDBE;
  line-height: 14px;
  margin-top: 13px;
}
.minheight{
  min-height: 200px;
}
.topHeader{
  position: static;
  flex: none;
}
.blue_btn_middle {
  width: 150px;
  padding: 11px 23px;
  font-size: 16px;
  box-shadow: 0px 1px 4px 0px rgba(26, 44, 119, 0.4);
}
</style>