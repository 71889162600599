<!--
 * @FilePath: /www.feimaoyun.com_html_source/src/views/DownPage/pagea.vue
 * @Description: 下载页面组件
-->

<template>
  <div>
    <MobilePage v-if="isMobile">123</MobilePage>
    <!-- pageMode1 --无收益模式 -->
    <pageMode1 v-else-if="mode == 1"></pageMode1>
    <!-- pageMode2 --现有的下载模式（下载量返利模式） -->
    <pageMode2 v-else-if="mode == 2"></pageMode2>
    <!-- pageMode3 --会员返利模式下载页(达人返利) -->
    <pageMode3 v-else-if="mode == 3"></pageMode3>
    <!-- pageMode4 -- 现在的会员返利和无收益模式其他不正常文件进的页面 更新埋点即可 -->
    <pageMode4 v-else-if="mode == 4"></pageMode4>
  </div>
</template>

<script>
import NavTop from "@/components/NavTop";
import MobilePage from "./compoents/mobilePage.vue";
import pageMode1 from "./compoents/pageMode1.vue";
import pageMode2 from "./compoents/pageMode2.vue";
import pageMode3 from "./compoents/pageMode3.vue";
import pageMode4 from "./compoents/pageMode4.vue";
import { getFileRebateModelAPI } from "@/utils/apiList/downpage";

export default {
  components: { NavTop, MobilePage, pageMode1, pageMode2, pageMode3, pageMode4 },
  data() {
    return {
      mode: -1,
      code: "",
      isMobile:false
    };
  },
  created() {
    this.code = this.$route.params.id;
    this.upadateDownPage();
    const ua = navigator.userAgent;
    this.isMobile = (!ua.match(/(iPad).*OS\s([\d_]+)/) && ua.match(/(iPhone\sOS)\s([\d_]+)/)) || ua.match(/(Android)\s+([\d.]+)/);
  },
  methods: {
    upadateDownPage() {
      getFileRebateModelAPI({ code: this.code }).then((res) => {
        if (res.status == "1") {
          this.mode = res.data.rebate_model;
        } else if (
          res.status == "60001" ||
          res.status == "50006" ||
          res.status == "60004"
        ) {
          //文件不存在或者被删除,锁定,私有文件拒绝访问
          this.mode = 4;
        }
        localStorage.setItem("rebate_model", res.data.rebate_model);
        this.$store.commit("setAttr", {
          name: "rebate_model",
          val: res.data.rebate_model,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
