import { render, staticRenderFns } from "./Modifyintercept.vue?vue&type=template&id=12c58d08&scoped=true"
import script from "./Modifyintercept.vue?vue&type=script&lang=js"
export * from "./Modifyintercept.vue?vue&type=script&lang=js"
import style0 from "./Modifyintercept.vue?vue&type=style&index=0&id=12c58d08&prod&lang=scss&scoped=true"
import style1 from "./Modifyintercept.vue?vue&type=style&index=1&id=12c58d08&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12c58d08",
  null
  
)

export default component.exports