<template>
    <el-dialog
        :visible.sync="isShow"
        :append-to-body="true"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        width="480px"
        custom-class="titleDialog"
    >
      <div class="dialog-body">
        <div class="d-header">
          <div class="left">
            <span>福利点规则</span>
          </div>
          <fm-button @click="handleClose" type="info" size="mini" text icon="icon-model-close"></fm-button>
        </div>
        <div class="d-content">
          <p v-for="item in content">{{ item }}</p>
        </div>
        <!-- <div class="d-footer">
          <fm-button type="primary" size="small" @click="handleConfirm">确 定</fm-button>
          <fm-button @click="handleClose" type="info" plain size="small">取 消</fm-button>
        </div> -->
      </div>
    </el-dialog>
  </template>
  
  <script>
import {
  getRule
} from "@/utils/apiList/welfare";

  export default {
    name:'title',
    props:{
      isShow: {
        type: Boolean,
        default: false
      },
    },
    data(){
      return{
        content: []
      }
    },
    mounted() {
      getRule().then(res => {
        this.content = res.data.list
      })
    },
    methods:{
      handleClose(){
        this.$emit('handleClose')
      },
      handleConfirm() {
        this.$emit('handleConfirm')
      }
    }
  }
  </script>
  
  <style lang="scss">
  @import "./dialog.scss";
  </style>
  