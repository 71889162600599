import { appDownUrl } from "@/utils/apiList/downpage";

async function getDlUrl() {
	let _url = ''
	try {
		const res = await appDownUrl()
		if (res.status == '1') {
			_url = res.data.android_down_url
		}
	} catch (e) {
		_url = 'http://download.jvmao.com/feimao.apk'
	}
	return _url
}


const commons = {
	intent: {
		package: 'com.feemoo',
		scheme: 'fmdisk'
	}, //安卓原生谷歌浏览器必须传递 Intent 协议地址，才能唤起 APP
	reportUrl: 'https://jinshuju.net/f/fKYT3q', //实名举报中心
	appstore: 'https://apps.apple.com/cn/app/id1477946462', //苹果应用商店地址
	apkstore: 'https://download.jvmao.com/feimao.apk', //安卓应用商店地址
	url_path: 'fmdisk://feemoo.app/openwith', //打开飞猫盘app地址
	// jumptime: 3000, //跳转时间
	// countHandleVisiable: false, //是否隐藏页面
	// timer: null, //定时器

	getPhone() {
		var ua = navigator.userAgent.toLowerCase();
		var uat = navigator.userAgent;
		var phonedata = new Object();
		let is_weixin = ua.match(/MicroMessenger/i) == "micromessenger"; //微信
		let is_dingtalk = ua.match(/dingtalk/i) == "dingtalk"; //钉钉
		let is_Alipay = ua.match(/aliapp/i) == "aliapp"; //支付宝
		let is_weibo = ua.match(/weibo/i) == "weibo"; //微博
		let is_zhihu = ua.match(/zhihuhybrid/i) == "zhihuhybrid"; //知乎
		let is_qq = ua.match(/qqtheme/i) == "qqtheme"; //qq
		phonedata.is_baiduboxapp = ua.match(/baiduboxapp/i) == "baiduboxapp"; //百度app
		phonedata.is_android = uat.indexOf("Android") > -1 || uat.indexOf("Linux") > -1;
		phonedata.is_ios = !!uat.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
		phonedata.is_dingtalk = is_dingtalk; //钉钉
		phonedata.isOriginalChrome = /chrome\/[\d.]+ Mobile Safari\/[\d.]+/i.test(uat) && phonedata.is_android;
		if (is_weixin || is_dingtalk || is_Alipay || is_weibo || is_zhihu || is_qq) {
			phonedata.third_party_browser = true
		} else {
			phonedata.third_party_browser = false
		}
		return phonedata
	},
	clickOpen(phonedata, pageData, params) {
		var that_url_path = this.url_path
		let arr = []
		if (pageData) {
			if (pageData.type) {
				arr.push('type=' + pageData.type)
			}
			if (pageData.id) {
				arr.push('id=' + pageData.id)
			}
			if (params.pucode) {
				arr.push('pucode=' + params.pucode)
			}
			if (params.code) {
				arr.push('fshort=' + params.code)
			}
			if (arr.length > 0) {
				that_url_path += '?' + arr.join('&')
			}
		}
		if (phonedata.is_android) {
			if (phonedata.isOriginalChrome) {
				if (typeof this.intent !== undefined) {
					window.top.location.href = that_url_path;
				} else { // scheme 在 andriod chrome 25+ 版本上必须手势触发
					const tagA = document.createElement('a');
					tagA.setAttribute('href', that_url_path);
					tagA.style.display = 'none';
					document.body.appendChild(tagA);
					tagA.click();
				}
			} else { //android设备其他应用
				const iframe = document.createElement('iframe');
				iframe.frameborder = '0';
				iframe.src = that_url_path;
				iframe.style.cssText = 'display:none;border:0;width:0;height:0;';
				document.body.appendChild(iframe);
			}
		} else  {
			console.log(that_url_path);
			window.top.location.href = that_url_path;
		}
	},
	/**
	 * 获取判断页面 显示|隐藏 状态改变的属性，webkitvisibilitychange/mozvisibilitychange/msvisibilitychange/ovisibilitychange/visibilitychange文档的可见性改变时触发
	 */
	getVisibilityChangeProperty() {
		const prefix = this.getPagePropertyPrefix();
		if (prefix === false) return false;
		return `${prefix}visibilitychange`;
	},
	/**
	 * 获取页面隐藏属性的前缀
	 * 如果页面支持 hidden 属性，返回 '' 就行
	 * 如果不支持，各个浏览器对 hidden 属性，有自己的实现，不同浏览器不同前缀，遍历看支持哪个
	 */
	getPagePropertyPrefix() {
		const prefixes = ['webkit', 'moz', 'ms', 'o'];
		let correctPrefix;
		if ('hidden' in document) return '';
		prefixes.forEach((prefix) => {
			if (`${prefix}Hidden` in document) {
				correctPrefix = prefix;
			}
		});
		return correctPrefix || false; //返回结果是'webkit', 'moz', 'ms', 'o' ,false
	},
	/**
	 * 判断页面是否隐藏（进入后台）
	 */
	isPageHidden() {
		const prefix = this.getPagePropertyPrefix();
		if (prefix === false) return false;
		const hiddenProperty = prefix ? `${prefix}Hidden` : 'hidden';
		return document[
			hiddenProperty
			]; //返回结果是document.hidden，document.mozHidden，document.msHidden， document.webkitHidden，document.oHidden,是判断页面是否隐藏（进入后台）
	},
	checkOpen(failCallback, timeout = 1500) {
		const visibilityChangeProperty = this.getVisibilityChangeProperty();
		const timer = setTimeout(() => {
			const hidden = this.isPageHidden(); //判断页面是否隐藏（进入后台）
			if (!hidden) { //没有进入后端，说明唤起失败，唤起失败，就执行失败的函数
				failCallback();
			}
		}, timeout);
		if (visibilityChangeProperty) {
			document.addEventListener(visibilityChangeProperty, () => {
				clearTimeout(timer);
			});

			return;
		}

		window.addEventListener('pagehide', () => { //页面关闭时 清除定时器
			clearTimeout(timer);
		});
	},
	// 判断文件类型对应的图标-s
	in_array(search, arr) {
		for (var i = 0, len = arr.length; i < len; i++) {
			if (arr[i] === search) {
				return true
			}
		}
		return false
	},
	getPrivateFileExt(ext) {
		if (this.in_array(ext, ['apk', 'mp3', 'exe', 'txt'])) {
			return ext
		} else if (
			this.in_array(ext, ['PNG', 'png', 'jpg', 'gif', 'bmp', 'jpeg'])
		) {
			return 'png'
		} else if (this.in_array(ext, ['zip', 'rar', '7z', 'tar', 'iso', 'gz'])) {
			// '7z', 'tar', 'iso', 'gz'
			return 'zip'
		} else if (
			this.in_array(ext, [
				'docx',
				'doc',
				'dot',
				'wps',
				'wpt',
				'dotx',
				'docm',
				'dotm',
				'rtf'
			])
		) {
			return 'doc'
		} else if (
			this.in_array(ext, [
				'ppt',
				'pptx',
				'pptm',
				'ppsx',
				'ppsm',
				'pps',
				'potx',
				'potm',
				'dpt',
				'dps'
			])
		) {
			return 'ppt'
		} else if (
			this.in_array(ext, [
				'xls',
				'xlsx',
				'et',
				'xlt',
				'xltx',
				'csv',
				'xlsm',
				'xltm'
			])
		) {
			return 'xls'
		} else if (this.in_array(ext, ['pdf'])) {
			// return 'pdf'
			return 'common'
		} else if (this.in_array(ext, ['mp4', 'MP4', 'mov', 'flv', 'avi', 'rmvb'])) {
			return 'mp4'
		} else if (this.in_array(ext, ['ipa', 'dmg'])) {
			return 'ipa'
		} else if (this.in_array(ext, ['folder'])) {
			return 'folder'
		} else {
			return 'common'
		}
	},
	fileIcon(ext) {
		let type = this.getPrivateFileExt(ext)
		switch (type) {
			case 'png':
				return 'icon_photo'
			case 'zip':
				return 'icon_zip'
			case 'doc':
				return 'icon_word'
			case 'ppt':
				return 'icon_ppt'
			case 'apk':
				return 'icon_apk'
			case 'txt':
				return 'icon_txt'
			case 'xls':
				return 'icon_excelicon'
			case 'mp3':
				return 'icon_music'
			case 'mp4':
				return 'icon_video'
			case 'ipa':
				return 'icon_mac'
			case 'exe':
				return 'icon_exe'
			case 'common':
				return 'icon_common'
			case 'folder':
				return 'icon_files'
		}
	},
	// 判断文件类型对应的图标-e
}

try {
	getDlUrl().then(res => {
		commons.apkstore = res
	})
} catch (e) {}


export default commons
