<template>
  <el-dialog
    title="提示"
    :visible.sync="isShow"
    width="810px"
    :before-close="handleClose"
    custom-class="PayJurisdictionDialog"
    :append-to-body="true"
  >
    <div style="max-height: 100vh;">
      <div class="dialog-body">
        <div class="d-header" style="margin-bottom: 8px;">
          <div class="left">{{$t('vip.allBenefits')}}</div>
          <fm-button
            type="info"
            size="mini"
            text
            icon="icon-model-close"
            @click="handleClose"
          ></fm-button>
        </div>
        <PayJurisdiction :widthNum="true" :PayJurisUrl="0" :windowHeight="windowHeight"></PayJurisdiction>
      </div>
    </div>
  </el-dialog>
</template>
  
  <script>
import PayJurisdiction from "@/components/PayJurisdiction/index";
export default {
  name: "title",
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PayJurisdiction,
  },
  data() {
    return {
      windowHeight: window.innerHeight,
    };
  },
  mounted(){
    // 监听窗口大小变化
    window.addEventListener('resize', this.updateHeight);
  },
  beforeCreate() {
    // 移除事件监听器
    window.removeEventListener('resize', this.updateHeight);
  },
  methods: {
    updateHeight() {
      this.windowHeight = window.innerHeight;
    },
    handleClose() {
      this.$emit("handleClose");
    },
  },
};
</script>
  
<style lang="scss" scoped>
.PayJurisdictionDialog {
  .left {
    height: 24px;
    font-weight: 500;
    font-size: 16px;
    @include useTheme {
      color: getVar("text-color2-2c2c2c");
    }
    line-height: 24px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }
  .tab {
    margin-top: 36px;
    margin-bottom: 24px;
    height: 36px;
    @include useTheme {
      box-shadow: getVar("shadow-e5e5e5");
    }
    span {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      cursor: pointer;
    }
    .active {
      color: #f67942;
      border-bottom: 4px solid #f67942;
      padding-bottom: 10px;
    }
  }
}

</style>
  
<style lang="scss">
.PayJurisdictionDialog {
  .el-dialog__body {
    padding: 0 !important;
    .dialog-body {
      padding: 20px 24px 24px;
      @include useTheme{
          background: getVar("text-color3-ffffff");
      }
      border-radius: 16px;
    }
  }
}
</style>