import request from "../api/request";
import utils from "../../utils/index";
import pfile from "@/utils/secretkey/pfile";
import sfile from "@/utils/secretkey/sfile";

var ak = utils.genak();
var ed = utils.re(ak, unescape(pfile)); //秘钥

function decrypt(data){
    if (data?.ak){
        let plaintext = utils.rd(data.ak, unescape(sfile));
        let obj = utils.secret(data.ed, plaintext, true);
        return JSON.parse(obj);
    }else{
        return data
    }
}

function inParameter(data){
    if (data){
        return { sn: ed, jt: utils.secret(data, ak)}
    }else{
        return data
    }
}

export function userSign(data) {
    return request({
        url: "/user-service/welfare/signInReward",
        method: "post",
        data: inParameter(data),
    }).then((res)=>{
        res.data = decrypt(res.data)
        return res
    }).catch((err) => {
        console.log(err);
    });
}

export function welfareText(data) {
    return request({
        url: "/user-service/welfare/welfareText",
        method: "post",
        data: inParameter(data),
    }).then((res)=>{
        res.data = decrypt(res.data)
        return res
    }).catch((err) => {
        console.log(err);
    });
}

export function getRule() {
    return request({
        url: "/user-service/welfare/getRule",
        method: "post",
    }).then((res)=>{
        res.data = decrypt(res.data)
        return res
    }).catch((err) => {
        console.log(err);
    });
}

export function userSignProgress(data) {
    return request({
        url: "/user-service/welfare/signInRewardBar",
        method: "post",
        data: inParameter(data),
    }).then((res)=>{
        res.data = decrypt(res.data)
        return res
    }).catch((err) => {
        console.log(err);
    });
}
export function welfareTaskInfo(data) {
    return request({
        url: "/user-service/welfare/taskInfoV2",
        method: "post",
        data: inParameter(data),
    }).then((res)=>{
        res.data = decrypt(res.data)
        return res
    }).catch((err) => {
        console.log(err);
    });
}
export function welfareRecord(data) {
    return request({
        url: "/user-service/welfare/record",
        method: "post",
        data: inParameter(data),
    }).then((res)=>{
        res.data = decrypt(res.data)
        return res
    }).catch((err) => {
        console.log(err);
    });
}
export function welfareRules(data) {
    return request({
        url: "/user-service/welfare/getRule",
        method: "post",
        data: inParameter(data),
    }).then((res)=>{
        res.data = decrypt(res.data)
        return res
    }).catch((err) => {
        console.log(err);
    });
}
export function welfareExchangeReward(data) {
    return request({
        url: "/user-service/welfare/exchangeReward",
        method: "post",
        data: inParameter(data),
    }).then((res)=>{
        res.data = decrypt(res.data)
        return res
    }).catch((err) => {
        console.log(err);
    });
}
export function downPageExtraInfo(data) {
    return request({
        url: "/user-service/welfare/getDownPageExtraInfo",
        method: "post",
        data: inParameter(data),
    }).then((res)=>{
        res.data = decrypt(res.data)
        return res
    }).catch((err) => {
        console.log(err);
    });
}
