<template>
  <div class="PayJurisdiction">
    <img
      :style="{
        width: !widthNum
          ? '1200px'
          : widthNum && windowHeight > 1000
          ? '762px'
          : '762px',
      }"
      class="PayJurisdictionbg"
      :src="
        $store.state.language == 'cn'
          ? removePngExtension($store.state.memberBenefitsImg[PayJurisUrl]) +
            '_' +
            $store.state.theme +
            '.png'
            : removePngExtension($store.state.memberBenefitsImg[PayJurisUrl]) +
            '_' +
            $store.state.language +
            '_' +
            $store.state.theme +
            '.png'
      "
      alt=""
    />
    <span
      class="v-desc"
      style="text-align: left;"
      :style="{ marginTop: PayJurisUrl == 0 ? '12px' : '' }"
      >{{ $t("vip.vipIntr") }}</span
    >
  </div>
</template>

<script>
export default {
  props: {
    widthNum: {
      type: Boolean,
      default: false,
    },
    PayJurisUrl: {
      type: Number,
      default: 1,
    },
    windowHeight: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    removePngExtension(url) {
      // 使用正则表达式替换 .png
      return url.replace(/\.png$/, "");
    },
  },
};
</script>

<style lang="scss">
@import "./index.scss";
</style>
